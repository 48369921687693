import * as domUtils from 'resources/js/utils/dom';
import * as gtmService from 'resources/js/services/gtm-service';

function bindClickEvent(domElement) {

    domElement.addEventListener('click', function(e) {
        e.preventDefault();

        var domShortcut = domUtils.findElementUpwardsByClassName(e.target, 'shortcuts__link');
        if (!domShortcut) return;

        var label = domShortcut.getAttribute('data-shortcuts-item-label');
        var link = domShortcut.getAttribute('href');

        gtmService.push({
            eventName: 'customEvents',
            eventAction: link,
            eventCategory: 'VVDG-komponenten',
            eventLabel: label,
            eventTimeout: 1000,
            eventCallback: function() {
                setTimeout(function() {
                    window.location = link;
                }, 100);
            }
        });

    });
}

function initialize() {
    [].slice.call(document.querySelectorAll('[data-shortcuts]')).forEach(bindClickEvent);
}


export { initialize }