import modal from 'resources/js/modal';

var modalList = [];
var targetList = [];

function createYoutubeModals(targetElements, youtubeIdDataAttribute, youtubeConsent) {

    var clickEventCreater = function() {
        return function(event) {
            event.preventDefault();
            event.stopPropagation();
            requireModal(this);
        };
    };

    var requireModal = function(target) {

        target.removeEventListener('click', target.clickFunc);
        var youtubeId = target.getAttribute(youtubeIdDataAttribute);

        if (youtubeConsent) {

            modalList.push(modal({
                openTrigger: [target],
                content: '<iframe id="player" type="text/html" width="100%" height="390" src="//www.youtube.com/embed/' + youtubeId + '?enablejsapi=1&showinfo=0&rel=0&autoplay=1" frameborder="0"></iframe>',
                modifier: 'linkmodal__content_youtube',
                runBeforeOpen: function(target, modal) {
                    var iframe = modal.getElementsByTagName('iframe')[0].contentWindow;
                    iframe.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                },
                runAfterClose: function(target, modal) {
                    var iframe = modal.getElementsByTagName('iframe')[0].contentWindow;
                    iframe.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                },
                clickOnTargetAfterInit: true,
                closeButtonColor: 'white'
            }));

        } else {

            var noConsentModalHtml = '' +
            '<div class="alert linkmodal__content-alert">' +
                '<div class="alert__icon-container">' +
                    '<svg class="alert__icon" viewBox="0 0 700 700">' +
                        '<path d="M686.1,569.6L426.7,51.2c-42.2-68.2-111.2-68.2-153.3,0L13.8,569.6C-22,641.3,14.2,700,94.5,700h511.1  C685.7,700,722,641.3,686.1,569.6z M302.6,217.1c0-26.2,21.2-47.4,47.4-47.4s47.4,21.2,47.4,47.4v214.6c0,26.2-21.2,47.4-47.4,47.4  s-47.4-21.2-47.4-47.4V217.1 M397.4,574.6c0,26.2-21.2,47.4-47.4,47.4c-26.2,0-47.4-21.2-47.4-47.4c0-26.2,21.2-47.4,47.4-47.4  C376.2,527.2,397.4,548.4,397.4,574.6z"></path>' +
                    '</svg>' +
                '</div>' +
                '<div class="alert__text">' +
                    'Dina cookie-inställningar på webbsidan tillåter inte att vi visar upp videoklippet, ' +
                    'eftersom det levereras av Youtube. Om du vill fortsätta, ' +
                    '<a href="//www.youtube.com/watch?v=' + youtubeId + '" target="_blank">öppna klippet på Youtube</a> ' +
                    'eller <a href="#" class="alert__link-consent-preferences">öppna dina inställningar</a> och godkänn nivå 2.' +
                '</div>' +
            '</div>';

            modalList.push(modal({
                openTrigger: [target],
                content: noConsentModalHtml,
                modifier: 'linkmodal__content_no-consent',
                clickOnTargetAfterInit: true,
                closeButtonColor: 'black',
                runAfterOpen: function(target, modal, closeTarget) {

                    var openConsentPreferencesLink = modal.querySelector('.alert__link-consent-preferences');

                    openConsentPreferencesLink.addEventListener('click', function closeFunction(event) {
                        event.preventDefault();
                        this.removeEventListener('click', closeFunction);
                        closeTarget();
                        window.websiteCookieConsent.openConsentPreferences();
                    });
                }
            }));
        }
    };

    targetElements.forEach(function(target) {

        target.clickFunc = clickEventCreater();
        target.addEventListener('click', target.clickFunc, false);
        targetList.push(target);

        var imageElement = target.querySelector('[data-youtube-thumbnail]');
        if (imageElement) {
            if (youtubeConsent) {
                imageElement.src = imageElement.getAttribute('data-youtube-thumbnail') || '/.resources/discover/resources/imgs/youtube_default_1280x720.jpg';
            } else {
                imageElement.src = '/.resources/discover/resources/imgs/youtube_default_1280x720.jpg';
            }
        }

    });

}

function initialize(consent) {
    modalList.forEach(function(modal) {
        modal.destroy();
    });

    targetList.forEach(function(target) {
        target.removeEventListener('click', target.clickFunc);
    });

    createYoutubeModals([].slice.call(document.querySelectorAll('[data-disc-open-modal-url]')), 'data-youtube-id', consent);
    createYoutubeModals([].slice.call(document.querySelectorAll('[data-embedded-youtube-id]')), 'data-embedded-youtube-id', consent);

}

export { initialize }
