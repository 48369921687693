/* global define */


var getSlideElementOriginalHeight = function(element) {
    element.style.cssText = 'position: absolute; visibility: hidden; display: block;';
    return element.offsetHeight;
};

var getOpenButton = function(element) {
    return element.querySelector('.button .tsPageSection-collapsed');
};

var getCloseButton = function(element) {
    return element.querySelector('.button .tsPageSection-opened');
};

function initCollapse(trigger, target) {
    var collapse = {
        open: false,
        slideTrigger: trigger,
        slideElement: target,
        slideElementOriginalHeight: getSlideElementOriginalHeight(target),
        openButton: getOpenButton(trigger),
        closeButton: getCloseButton(trigger),
        toggleOpen: function() {
            this.slideElement.style.cssText = 'transition: height 300ms; -webkit-transition: height 300ms; -moz-transition: height 300ms; -o-transition: height 300ms; overflow: hidden;';
            this.slideElement.style.height = this.slideElementOriginalHeight + 'px';
            this.slideElement.setAttribute('aria-hidden', false);
            this.slideTrigger.setAttribute('aria-expanded', true);
        },
        toggleClose: function() {
            this.slideElement.style.height = '0';
            this.slideElement.setAttribute('aria-hidden', true);
            this.slideTrigger.setAttribute('aria-expanded', false);
        },
        toggleButtonOpen: function() {
            this.openButton.style.display = 'block';
            this.closeButton.style.display = 'none';
        },
        toggleButtonClose: function() {
            this.openButton.style.display = 'none';
            this.closeButton.style.display = 'block';
        }
    };

    collapse.toggleClose();
    return collapse;
}

function attachClickEventHandler(collapse) {
    collapse.slideTrigger.addEventListener('click', function() {
        if (collapse.open) {
            collapse.toggleClose();
            collapse.toggleButtonOpen();
        } else {
            collapse.toggleOpen();
            collapse.toggleButtonClose();
        }
        collapse.open = !collapse.open;
    });

}

function attachResize(collapses) {
    window.addEventListener('resize', function() {
        collapses.forEach(function(collapse) {
            var totalHeight = 0;
            [].slice.call(collapse.slideElement.children).map(function(element) {
                totalHeight = totalHeight + element.offsetHeight;
            });
            collapse.slideElementOriginalHeight = totalHeight;
            if (collapse.open) {
                collapse.slideElement.style.height = collapse.slideElementOriginalHeight + 'px';
            }
        });
    });
}

function initialize(domElement) {
    var collapses = [];
    var pagesectionsWithCollapse = document.querySelectorAll('[data-page-section] > [data-widget=collapse]');
    [].slice.call(pagesectionsWithCollapse).map(function(pagesection) {
        var collapse = initCollapse(pagesection.querySelector('[data-widget-collapse=trigger]'),
            pagesection.querySelector('[data-widget-collapse=inner]'));
        attachClickEventHandler(collapse);
        collapses.push(collapse);
    });
    attachResize(collapses);
}

export { initialize }