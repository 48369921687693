
var componentCounter = {};

function bindComponent(element) {

    var componentName = element.getAttribute('data-discolytics-component');
    var componentLabel = element.getAttribute('data-discolytics-label');
    var componentPosition = componentCounter[componentName] || 1;

    var children = (element.hasAttribute('data-web-analytics-label'))
        ? [element] : [].slice.call(element.querySelectorAll('[data-web-analytics-label]'));

    var numOfChildren = children.length;

    children.forEach(function(child, index) {
        var childPosition = (numOfChildren > 1) ? '.' + (index + 1) : '';
        var label = componentName + '_(' + componentPosition + childPosition + ')_' + componentLabel;
        child.setAttribute('data-web-analytics-label', label);
    });

    componentCounter[componentName] = componentPosition + 1;

}

function initialize() {
    [].slice.call(document.querySelectorAll('[data-discolytics-component]')).forEach(bindComponent);
}

export { initialize }