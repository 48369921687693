// font-size: 1.5 rem (15px)
var mqs = {
    mqXsmall: 195, // 13em
    mqLtSmall: 464, // 30.99em
    mqSmall: 465, // 31em
    mqLtMedium: 659, // 43,99em
    mqMedium: 660, // 44em
    mqLtLarge: 839, // 55.99em
    mqLarge: 840, // 56em
    mqLtXlarge: 959, // 63.99em
    mqXlarge: 960 // 64em
};

function get(mq) {
    if (mq === null) {
        return null;
    }
    return mqs.hasOwnProperty(mq) ? mqs[mq] : null;
}

export { get }
