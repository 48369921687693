import * as objectUtils from 'resources/js/utils/object';
import * as logService from 'resources/js/services/log-service';


var translation = {

    // Event variables
    eventName: 't',
    eventLabel: 'el',
    eventCategory: 'ec',
    eventAction: 'ea',
    eventValue: 'ev',

    // Custom Dimensions
    abtestName: 'cd70'
};

function push(data) {
    pushToGTM(data);
    pushToTeliaGA(data);
}

function pushToGTM(data) {

    var dataLayer = window.dataLayer;

    var obj = objectUtils.extend(data, data.customParameters || {});
    obj.event = obj.eventName;
    delete obj.eventName;
    delete obj.customParameters;

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(obj);
        logService.debug('gtmService push:', data);
    } else {
        // logService.info('GTM dataLayer not available');
    }

    if ((!window['google_tag_manager']) && (typeof data.eventCallback !== 'undefined')) {
        data.eventCallback();
    }

}

function pushToTeliaGA(data) {
    var ta = window.teliaAnalytics;
    ta.event(data.eventCategory, data.eventAction, data.eventLabel, translateObject(data.customParameters || {}));
}

function translateObject(data) {
    var obj = {};
    for (var key in data) {
        obj[(translation[key] || key)] = (typeof data[key] === 'object') ? translateObject(data[key]) : data[key];
    }
    return obj;
}


export { push, pushToGTM, pushToTeliaGA }