/**
 * [pricelist description]
 * Used for ?
 * @author Mats Lindblad <mats.lindblad@teliacompany.com>
 * @version 1.1.0
 */

import jQuery from 'jquery';
import * as mq from 'resources/js/utils/media-queries';

function highestDomElement(elements) {
    const height = [];
    for (let i = 0, len = elements.length; i < len; i++) {
        height.push(elements[i].offsetHeight);
    }
    return Math.max.apply(null, height);
}

function mediaqueriesMax(size) {
    let pxWidth = mq.get(size);
    if (pxWidth) {
        let mql = window.matchMedia('(max-width: ' + pxWidth + 'px)');
        return mql.matches;
    }
    return false;
}

let $ = jQuery;
let _pls;
let PricelistWidget = {
    init: function() {
        _pls = {
            priceList: $('.tsPriceList'),
            priceListSections: $('.tsPriceList-Section'),
            priceListInner: $('.tsPriceList-Section-inner'),
            priceListOuter: $('.tsPriceList-Section-outer'),
            contentContainer: $('.tsPriceList-Content'),
            contentInnerContainer: $('.tsPriceList-Content-Inner'),
            contentSections: $('.tsPriceList-Content-Section'),
            contentPlaceholder: $('#tsPriceList-Content-Placeholder'),
            contentMobile: $('.tsPriceList-Mobile-Content'),
            contentMiddleCls: 'tsPriceList-Content-middle',
            nSectionsTablet: 3,
            /*
             * change to lt-ie10 when available
             */
            gtIe10: !$('body').hasClass('lt-ie10')
        };
        this.setModifierOnWrapper();
        this.bindActions();
        this.updateView(true);
    },
    /*
     * Set modifier for tsWrapInner on page-section
     */
    setModifierOnWrapper: function() {
        const tcPriceList = document.querySelector('.tsPriceList');
        if (tcPriceList) {
            tcPriceList.parentElement.classList.add('tsPriceList--wrap-fix');
            tcPriceList.firstElementChild.classList.add('tsPriceList--wrap-fix');
        }
    },
    /*
     * Initiate all UI-events
     */
    bindActions: function() {
        const me = this;
        // Mobile mode
        _pls.priceListSections.on('click', '.tsResponsiveTabs-Anchor', function(e) {
            console.log('clicked', this);
            let $this = $(this);
            let contentId = $this.attr('href');
            e.preventDefault();
            if (mediaqueriesMax('mqLtMedium') && _pls.gtIe10) {
                me.toggleListItem($this, contentId);
            }
        });
        // Desktop mode
        _pls.priceListSections.on('click', 'li > a[href^="#"]', function(e) {
            let $this = $(this);
            let contentId = $this.attr('href');
            e.preventDefault();
            /*
            * Disable multiple clicks...
            */
           if ($(':animated').length) {
               return false;
            }
            if (mediaqueriesMax('mqLtMedium') && _pls.gtIe10) {
                me.expandMobile($this, contentId);
            } else if (mediaqueriesMax('mqLtXlarge') && _pls.gtIe10) {
                me.expandTablet($this, contentId);
            } else {
                _pls.contentPlaceholder.slideUp(function() {
                    me.expandDesktop($this, contentId);
                });
            }
        });
        if (!_pls.gtIe10) {
            return;
        }
        /*
         * Collapse all on section change on mobile
         */
        _pls.priceList.on('click touchstart', '[data-widget-collapse=trigger]', function() {
            let $outer = $(this).parents('.tsPriceList-Section-outer');
            $outer.find('.tsPriceList-Mobile-Content').slideUp();
            PricelistWidget.removeIsExpandedAll($outer);
        });
        this._setupViewUpdate();
    },

    _setupViewUpdate: function() {
        const me = this;
        const tcPriceList = document.querySelector('.tsPriceList');
        if (tcPriceList) {
            let resizeTimer;
            $(window).on('resize', function() {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function() {
                    me.updateView();
                }, 100);
            });
            tcPriceList.addEventListener('click', (e) => {
                if (e.target.matches('a[href^="#"]')) {
                }
            });
        }
    },

    /*
     * Removes all instances of the is expanded class
     */
    removeIsExpandedAll: function($container) {
        let $ct = $container || _pls.priceListInner;
        $ct.find('.tsPriceList-isExpanded').removeClass('tsPriceList-isExpanded');
    },
    toggleCollapseStatus: function($href, isExpanded) {
        $href.toggleClass('tsPriceList-isExpanded', typeof isExpanded !== 'undefined' ?
            isExpanded : $href.hasClass('tsPriceList-isExpanded'));
    },
    isExpanded: function($href) {
        return $href instanceof jQuery && $href.hasClass('tsPriceList-isExpanded');
    },
    updateViewLarge: function(isInitial, gtIe10) {
        if (_pls.mobileLast || isInitial) {
            const lists = [...document.querySelectorAll('.tsPriceList-Section-inner')];
            const height = highestDomElement(lists);
            lists.forEach((list) => {
                list.style.height = height + 'px';
            });
        }
        if (mediaqueriesMax('mqLtXlarge') && gtIe10) {
            if (!(_pls.tabletLast && _pls.mobileLast) || isInitial) {
                PricelistWidget.movePriceContent();
            }
            _pls.mobileLast = false;
            _pls.tabletLast = true;
        } else {
            if (_pls.tabletLast) {
                // Move content container from tablet position to bottom in desktop
                _pls.contentPlaceholder.hide();
                $('#tsPriceList-Content-Home').html($('.tsPriceList-Content').detach());
            }
            _pls.mobileLast = false;
            _pls.tabletLast = false;
        }
    },
    /*
     * Run on every window change to check if media breakpoints has changed
     */
    updateView: function(isInitial) {
        let gtIe10 = _pls.gtIe10;
        if (mediaqueriesMax('mqLtMedium') && gtIe10) {
            // First into mobile
            if (!_pls.mobileLast || isInitial) {
                _pls.priceListInner.height('auto');
                _pls.priceListSections.removeClass('active');
            }
            _pls.mobileLast = true;
        } else {
            this.updateViewLarge(isInitial, gtIe10);
        }
    },
    /*
     * Move the content container to allow placement in between rows (tablet view)
     */
    movePriceContent: function() {
        let me = this;
        let sections = _pls.priceListSections;
        let contentContainer = _pls.contentContainer;
        let activeSection = _pls.priceListSections.siblings('.active');

        me.getLastSectionInRow(sections, activeSection).after(contentContainer);

        if (sections.parent().children().last().is(contentContainer)) {
            _pls.contentInnerContainer.removeClass('tsPriceList-Content-middle');
            _pls.contentPlaceholder.slideUp();
        } else {
            _pls.contentInnerContainer.addClass('tsPriceList-Content-middle');
            _pls.contentPlaceholder.slideDown();
        }
    },
    expandDesktop: function($collapseTrigger, contentId, beforeSlideFn) {
        _pls.contentInnerContainer.slideUp(function() {
            _pls.contentSections.hide();
            _pls.priceListSections.removeClass('active');
            // Not expanded
            if (!PricelistWidget.isExpanded($collapseTrigger)) {
                $collapseTrigger.parents('.tsPriceList-Section').addClass('active');
                $(contentId).show();
                _pls.contentInnerContainer.slideDown(function() {
                    PricelistWidget.scrollToHeadline($collapseTrigger);
                });
                PricelistWidget.removeIsExpandedAll();
                PricelistWidget.toggleCollapseStatus($collapseTrigger, true);
                if (typeof beforeSlideFn !== 'undefined') {
                    beforeSlideFn();
                }
            } else {
                _pls.contentPlaceholder.slideDown();
                PricelistWidget.toggleCollapseStatus($collapseTrigger, false);
            }
        });
    },
    expandTablet: function($collapseTrigger, contentId) {
        let me = this;
        let $currSection = $collapseTrigger.parents('.tsPriceList-Section');

        me.expandDesktop($collapseTrigger, contentId, function() {
            me.movePriceContent($currSection);
        });
    },
    expandMobile: function($collapseTrigger, contentId) {
        let $mobileContainer = $collapseTrigger.parents('ul').find('.tsPriceList-Mobile-Content');
        let $li = $collapseTrigger.parents('li');

        $mobileContainer.slideUp(null, function() {
            if (!PricelistWidget.isExpanded($collapseTrigger)) {
                $li.after($mobileContainer.detach());
                $mobileContainer.html($(contentId).html()).slideDown();
                PricelistWidget.removeIsExpandedAll($collapseTrigger.parents('ul'));
                PricelistWidget.toggleCollapseStatus($collapseTrigger, true);
            } else {
                PricelistWidget.toggleCollapseStatus($collapseTrigger, false);
            }
        });


    },
    toggleListItem: function(contentId) {
        let toggleArrows = $(contentId).find('.toggle-icon');
        let parentElement = $(contentId).parent('div');
        let toggleElement = $(contentId).parent('div').find('.tsResponsiveTabs-Panel');
        parentElement.toggleClass("bg-white");
        toggleElement.slideToggle().next().toggleClass("hidden-mobile");
        toggleArrows.toggle();
    },
    scrollToHeadline: function($collapseTrigger, completeFn) {
        if (!PricelistWidget.isScrolledIntoView(_pls.contentInnerContainer)) {
            let offset = $collapseTrigger.parents('.tsPriceList-Section-inner')
                .find('.tsPriceListBigHeader > h2')
                .offset();
            if (!offset) {
                return;
            }
            $('html,body').animate({
                scrollTop: offset.top
            }, {
                complete: completeFn
            });
        }
    },
    isScrolledIntoView: function(elem) {
        let docViewTop = $(window).scrollTop();
        let docViewBottom = docViewTop + $(window).height();

        let elemTop = $(elem).offset().top;
        let elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    },
    getLastSectionInRow: function($sections, $activeSection, setMiddleClass) {
        let index = $activeSection.index();
        let lastIndex = _pls.priceListSections.length - 1;
        let lastIndexInRow = Math.min(this.getLastIndexInRow(index, 3), lastIndex);
        if (setMiddleClass && lastIndexInRow < lastIndex) {
            $activeSection.find('.tsPriceList-Content-Inner').addClass(this.contentMiddleCls);
        }
        return _pls.priceListSections.eq(lastIndexInRow);
    },
    getLastIndexInRow: function(index, rowSize) {
        return index - index % rowSize + rowSize - 1;
    }
};

function initialize() {
    if (document.readyState === 'complete') {
        
        PricelistWidget.init();
        
    } else {
        const interval = setInterval(() => {
            if (document.readyState === "complete") {
                PricelistWidget.init();
                clearInterval(interval);
            }
        }, 20);
    }
}

export { initialize }
