function hasClass(element, selector) {
    var className = ' ' + selector + ' ';
    if ((' ' + element.className + ' ').replace(/[\n\t]/g, ' ').indexOf(className) > -1) {
        return true;
    }
    return false;
}

function removeClass(element, selector) {
    var className = element.className;
    var rxp = new RegExp(selector, 'g');
    className = className.replace(rxp, '');
    element.className = className;
}

function addClass(element, selector) {
    var className = element.className.trim();
    if (className.indexOf(selector) > 0) {
        return;
    }
    if (className !== '') {
        selector = ' ' + selector;
    }
    element.className = className + selector;
}

function setSvg(element, value) {
    if (!element) {
        return;
    }
    element.setAttribute('xlink:href', value);
}

function cssToPx(css) {
    if (css.endsWith('rem')) {
        var remInPx = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
        return parseFloat(css.slice(0, -3)) * remInPx;
    } else if (css.endsWith('px')) {
        return parseFloat(css.slice(0, -2));
    } else {
        // throw new Error('Unknown css unit ' + css);
    }
}

function getTranslate3d(element) {
    var values = element.style.transform.split(/\w+\(|\);?/);
    if (!values[1] || !values[1].length) {
        return [];
    }
    return values[1].split(/,\s?/g);
}

function createDocumentHeightChangeListenerDOMNode(eventFunc) {
    document.body.style.position = 'relative';
    document.body.insertAdjacentHTML('afterbegin', '<iframe class="height-change-listener" tabindex="-1"></iframe>');
    return document.body.querySelector('.height-change-listener').contentWindow;
}

function findElementUpwardsByClassName(node, target) {
    if (!node) return;
    if (node.className === target) {
        return node;
    }
    return findElementUpwardsByClassName(node.parentNode, target);
}

export {  hasClass, addClass, removeClass, setSvg, cssToPx, getTranslate3d, 
    createDocumentHeightChangeListenerDOMNode ,findElementUpwardsByClassName
}