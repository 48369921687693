import * as domHandler from 'resources/js/utils/dom';
import * as clickOutside from 'resources/js/utils/click-outside';


var switches = [];
function createSwitch(domSwitch) {

    var selectArea = domSwitch.getElementsByClassName('content-switch__select-item_current')[0];
    var menuElements = domSwitch.getElementsByClassName('content-switch__select-list_bottom-fixed')[0].getElementsByClassName('content-switch__select-item');

    var contentSwitch = {
        contentElements: [].slice.call(domSwitch.getElementsByClassName('content-switch__content')),
        selectedIndex: 0,
        selectedLabel: selectArea.getElementsByTagName('SPAN')[0],
        slideElement: domSwitch.getElementsByClassName('content-switch__select-list_hidden')[0],
        menu: [],
        menuOriginalHeight: 0,
        toggling: false
    };

    var menuElementsWidths = [];
    for (var i = 0; i < menuElements.length; i++) {
        contentSwitch.menuOriginalHeight = contentSwitch.menuOriginalHeight + menuElements[i].offsetHeight + 1;
        contentSwitch.menu.push(menuElements[i].getElementsByTagName('SPAN')[0].innerHTML);
        menuElementsWidths.push(menuElements[i].offsetWidth + 1);
        menuElements[i].onclick = createMenuClickCallback(contentSwitch, i);
    }

    contentSwitch.slideElement.style.height = contentSwitch.menuOriginalHeight + 'px';
    contentSwitch.slideElement.style.width = Math.max.apply(Math, menuElementsWidths) + 'px';
    contentSwitch.slideElement.style.display = 'none';

    selectArea.onclick = function() {
        toggle(contentSwitch);
    };

    return contentSwitch;

}

function createMenuClickCallback(contentSwitch, menuIndex) {
    return function() {
        slideUp(contentSwitch);
        selectContent(contentSwitch, menuIndex);
        return false;
    };
}

function setSlideElementStyles(element, display, overflow, height) {
    element.style.display = display;
    element.style.overflow = overflow;
    element.style.height = height + 'px';
}

function toggle(contentSwitch) {
    if (!contentSwitch.toggling) {
        contentSwitch.toggling = true;
        if (contentSwitch.slideElement.style.display === 'none') {
            contentSwitch.slideElement.style.height = '0px';
            slideDown(contentSwitch);
        } else {
            slideUp(contentSwitch);
        }
    }
}

function slideDown(contentSwitch) {

    var currentHeight = contentSwitch.slideElement.clientHeight;
    if (currentHeight >= contentSwitch.menuOriginalHeight) {

        clickOutside.create({
            target: contentSwitch.slideElement,
            onClickInside: function(removeClickOutside) {
                removeClickOutside();
            },
            onClickOutside: function() {
                slideUp(contentSwitch);
            }
        });

        contentSwitch.toggling = false;
        return;
    }

    setSlideElementStyles(contentSwitch.slideElement, 'block', 'hidden', (currentHeight + 4));

    setTimeout(function() {
        slideDown(contentSwitch);
    }, 0);

}

function slideUp(contentSwitch) {

    var currentHeight = contentSwitch.slideElement.clientHeight;
    if (currentHeight <= 0) {
        setSlideElementStyles(contentSwitch.slideElement, 'none', '', contentSwitch.menuOriginalHeight);
        contentSwitch.toggling = false;
        return;
    }

    setSlideElementStyles(contentSwitch.slideElement, 'block', 'hidden', (currentHeight - 4));

    setTimeout(function() {
        slideUp(contentSwitch);
    }, 0);

}

function selectContent(contentSwitch, selectIndex) {
    var len = contentSwitch.contentElements.length;
    for (var i = 0; i < len; i++) {
        if (i === selectIndex) {
            domHandler.removeClass(contentSwitch.contentElements[i], 'content-switch__content_hidden');
        } else {
            domHandler.addClass(contentSwitch.contentElements[i], 'content-switch__content_hidden');
        }
    }
    contentSwitch.selectedIndex = selectIndex;
    contentSwitch.selectedLabel.innerHTML = contentSwitch.menu[selectIndex];
}

function initialize() {
    switches = [].slice.call(document.querySelectorAll('[data-content-switch-component]')).map(function(domSwitch) {
        var contentSwitch = createSwitch(domSwitch);
        selectContent(contentSwitch, 0);
        return contentSwitch;
    });
}




export { initialize }