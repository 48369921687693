
function extend() {
    var extendedObj = {};
    for (var i = 0; i < arguments.length; i += 1) {

        var obj = arguments[i];
        var keys = Object.keys(obj);

        for (var j = 0; j < keys.length; j += 1) {
            extendedObj[keys[j]] = obj[keys[j]];
        }

    }
    return extendedObj;
}

function stringToJSON(stringObject) {
    var object = {};
    if (stringObject !== '') {
        try {
            object = JSON.parse(stringObject);
        } catch (err) {
            console.log('Parse error:', err);
        }
    }
    return object;
}

export { extend, stringToJSON }