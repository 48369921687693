function buildOption(selectList, onSelectCallback) {
    return [].slice.call(selectList.dom.querySelectorAll('.select-list__list li')).map(function(element, idx) {
        var option = {
            id: element.getAttribute('data-value') || idx,
            text: element.innerText
        };

        element.addEventListener('click', function() {
            selectList.select(option.id);
            onSelectCallback(option.id);
        });

        return option;

    });
}

function buildSelectList(params) {
    var opt = {
        element: params.element || null,
        onSelect: params.onSelect || function() {}
    };
    var selectListElement = opt.element.querySelector('[data-select-list]') || null;
    var hintElementSpan = selectListElement.querySelector('.select-list__hint span') || null;

    var selectList = {
        selected: [],
        options: [],
        dom: selectListElement,
        hint: hintElementSpan,
        getSelected: function() {
            return this.selected;
        },
        select: function(selectedId) {
            this.selected = this.options.filter(function(option) {
                return (selectedId === option.id);
            })[0] || (this.options[0] || null);

            if (this.selected) {
                this.hint.innerText = this.selected.text;
            }
        }
    };

    selectList.options = buildOption(selectList, opt.onSelect);

    selectList.dom.addEventListener('click', function() {
        selectList.dom.classList.toggle('open', !selectList.dom.classList.contains('open'));
    });

    return selectList;

}

export default buildSelectList