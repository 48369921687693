var config = {
    selectors: {
        buttons: '.tsBtnMulti-Filter > button',
        filterTags: 'li[data-filtertags]',
        list: 'div[data-widget="filter"]',
        miscList: '.tsProductItem-Desc-Misc--List',
        btnChannels: '.tsBtnDouble-Button--Left',
        channels: '.tsLibraryItem .tsLibrary-img',
        popoverCloseBtn: '.popover-close',
        btnPlayServices: '.tsBtnDouble-Button--Right',
        discWithTabsForTvFilter: '.disc-with-tabs-for-tv__filter'
    },
    cssClasses: {
        hideListItem: 'tsProductList-Item--visuallyhidden'
    }
};

function ProductList(element) {
    this.element = element;
    this.smallScreenWatcher = matchMedia('(max-width: 44em)');
    this.mediumScreenWatcher = matchMedia('(min-width: 44.01em) and (max-width: 56em)');
    this.buttons = [...this.element.querySelectorAll(config.selectors.buttons)];
    this.channels = [...this.element.querySelectorAll(config.selectors.channels)];
    this.popoverCloseBtn = [...this.element.querySelectorAll(config.selectors.popoverCloseBtn)];
    this.list = this.element.querySelector(config.selectors.list);
    if (!this.list) {
        return;
    }
    this.list.hasEventListeners = false;
    this.clone = this.list.cloneNode(true);
    this.clone.classList.add('clone');
    this.clone.hasEventListeners = false;
    this.cloneChannels = [...this.clone.querySelectorAll(config.selectors.channels)];
    this.clonePopoverCloseBtn = [...this.clone.querySelectorAll(config.selectors.popoverCloseBtn)];

    // placeholders for properties set throughout the class, to keep track
    this.activeButton = null;
    this.clickedButton = null;

    if (this.buttons.length) {
        this.setup();
        this.setTitleTag();
    } else {
        this.eventListeners();
        this.showListItems();
    }
}

ProductList.prototype.setup = function() {
    var hashTarget = location.hash.slice(1);
    if (hashTarget) {
        hashTarget = this.hashToString(hashTarget);
    }

    this.buttons.forEach(function(button) {
        if (hashTarget !== '') {
            var dataValue = button.getAttribute('value');
            dataValue = this.hashToString(dataValue);
            if (dataValue === hashTarget) {
                this.activeButton = button;
                this.clickedButton = this.activeButton;
            }
        } else if (button.classList.contains('active')) {
            this.activeButton = button;
        }
        button.addEventListener('click', this.onClick.bind(this));
    }.bind(this));
    if (!this.activeButton) {
        this.activeButton = this.buttons[0];
    }
    this.setActive(this.activeButton);
    this.showListItems(this.activeButton);

    this.eventListeners();

    if (hashTarget !== '') { // scroll to focused tab if it has been linked to
        this.scrollToTopOfTab();
    }
};

ProductList.prototype.eventListeners = function() {
    var showPopoverOnMouseover = this.showPopoverOnMouseover.bind(this);
    var hidePopoverOnMouseout = this.hidePopoverOnMouseout.bind(this);
    var togglePopover = this.togglePopoverOnClick.bind(this);
    var closePopoverOnClick = this.closePopoverOnClick.bind(this);

    this.smallScreenWatcher.addListener(function(e) {
        this.cloneChannels.forEach(function(channel) {
            channel.addEventListener('click', togglePopover);
        }, this);

        this.clonePopoverCloseBtn.forEach(function(e) {
            e.addEventListener('click', closePopoverOnClick);
        }, this);

        var currentButton;
        this.buttons.forEach(function(button) {
            if (button.classList.contains('active')) {
                currentButton = button;
                this.showListItems(button);
            }
        }.bind(this));
        // No active button and on desktop, open first.
        if (!currentButton && !this.isMobile()) {
            this.setActive(this.buttons[0]);
            this.showListItems(this.buttons[0]);
            this.clickedButton = this.buttons[0];
        }

        this.scrollToTopOfTab();
    }.bind(this));

    this.mediumScreenWatcher.addListener(function(e) {
        if (e.matches) {
            this.channels.forEach(function(channel) {
                channel.removeEventListener('mouseover', showPopoverOnMouseover);
                channel.removeEventListener('mouseout', hidePopoverOnMouseout);
                channel.addEventListener('click', togglePopover);
            }, this);
            this.popoverCloseBtn.forEach(function(e) {
                e.addEventListener('click', closePopoverOnClick);
            }, this);
        } else {
            this.channels.forEach(function(channel) {
                channel.removeEventListener('click', togglePopover);
                channel.addEventListener('mouseover', showPopoverOnMouseover);
                channel.addEventListener('mouseout', hidePopoverOnMouseout);
            }, this);
        }
    }.bind(this));

    if (this.smallScreenWatcher.matches) {
        this.cloneChannels.forEach(function(channel) {
            channel.addEventListener('click', togglePopover);
        }, this);
        this.clonePopoverCloseBtn.forEach(function(e) {
            e.addEventListener('click', closePopoverOnClick);
        }, this);
    } else if (this.mediumScreenWatcher.matches) {
        this.channels.forEach(function(channel) {
            channel.addEventListener('click', togglePopover);
        }, this);
        this.popoverCloseBtn.forEach(function(e) {
            e.addEventListener('click', closePopoverOnClick);
        }, this);
    } else {
        this.channels.forEach(function(channel) {
            channel.addEventListener('mouseover', showPopoverOnMouseover);
            channel.addEventListener('mouseout', hidePopoverOnMouseout);
        }, this);
    }
};

ProductList.prototype.hashToString = function(hash) {
    return hash.toLocaleLowerCase()
        .replace(' ', '')
        .replace('+', '')
        .replace('%20', '');
};

ProductList.prototype.toggleHash = function() {
    if (this.clickedButton) {
        var value = this.clickedButton.getAttribute('value');
        var hash = location.hash.slice(1);
        if (value !== hash) {
            location.hash = this.hashToString(value);
        }
    }
};

ProductList.prototype.togglePackages = function(useElement) {
    var togglePackage = function(pkg, e) {
        e.preventDefault();
        var button = this;
        var channelList = pkg.channelsButton.actionElement;
        var playList = pkg.playServicesButton.actionElement;
        if (button === pkg.channelsButton.click) {
            if (button.classList.contains('active')) {
                button.classList.remove('active');
                if (channelList) {
                    channelList.style.display = 'none';
                }
            } else {
                button.classList.add('active');

                if (channelList) {
                    channelList.classList.add('visible');
                    channelList.style.display = '';
                }
                if (playList) {
                    pkg.playServicesButton.click.classList.remove('active');
                    playList.classList.remove('visible');
                    playList.style.display = 'none';
                }
            }
        }
        if (button === pkg.playServicesButton.click) {
            if (button.classList.contains('active')) {
                button.classList.remove('active');
                if (playList) {
                    playList.style.display = 'none';
                }
            } else {
                button.classList.add('active');
                if (playList) {
                    playList.classList.add('visible');
                    playList.style.display = '';
                }
                if (channelList) {
                    pkg.channelsButton.click.classList.remove('active');
                    channelList.classList.remove('visible');
                    channelList.style.display = 'none';
                }
            }
        }
        return false;
    };

    if (!useElement.hasEventListeners) {
        [].slice.call(useElement.querySelectorAll(config.selectors.miscList)).forEach(function(element) {
            var channelBtn = element.querySelector(config.selectors.btnChannels);
            var playBtn = element.querySelector(config.selectors.btnPlayServices);
            var channelsButton = {
                click: channelBtn,
                actionElement: element.querySelector('[role="channels"]')
            };
            var playServicesButton = {
                click: playBtn,
                actionElement: element.querySelector('[role="playServices"]')
            };
            var pkg = {
                element: element,
                channelsButton: channelsButton,
                playServicesButton: playServicesButton
            };
            var channelCallback = togglePackage.bind(channelsButton.click, pkg);
            var playCallback = togglePackage.bind(playServicesButton.click, pkg);

            if (channelBtn) {
                channelsButton.click.removeEventListener('click', channelCallback);
                channelsButton.click.addEventListener('click', channelCallback);
            }
            if (playBtn) {
                playServicesButton.click.removeEventListener('click', playCallback);
                playServicesButton.click.addEventListener('click', playCallback);
            }
        });
        useElement.hasEventListeners = true;
    }
};

ProductList.prototype.setTitleTag = function() {
    this.buttons.forEach(function(button) {
        if (button.classList.contains('active')) {
            var activeElement = document.querySelector('button.active > span');
            var label = activeElement.textContent || activeElement.innerText;
            var targetElements = document.querySelectorAll('.productlist-heading-label');
            targetElements.forEach(function(targetElement) {
                targetElement.innerHTML = label;
            });
        };
    });
};

ProductList.prototype.scrollToTopOfTab = function() {
    if (this.clickedButton) {
        try {
            // ScrollOptions - the future
            window.scrollTo({
                left: 0,
                top: Math.round(this.clickedButton.offsetTop),
                behavior: 'smooth'
            });
        } catch (err) {
            // Normal and boring and jerky
            window.scrollTo(0, Math.round(this.clickedButton.offsetTop));
        }
    }
};

ProductList.prototype.onClick = function(e) {
    this.clickedButton = e.currentTarget;
    // unset on click on active
    if (this.clickedButton.classList.contains('active') && this.isMobile()) {
        this.clickedButton.classList.remove('active');
        this.removeClone();
        return false;
    }
    this.setActive(this.clickedButton);
    this.showListItems(this.clickedButton);
    this.scrollToTopOfTab();
    this.setTitleTag();
    this.toggleHash();
};

ProductList.prototype.setActive = function(clicked) {
    this.buttons.forEach(function(button) {
        if (button === clicked) {
            button.classList.add('active');
        } else {
            button.classList.remove('active');
        }
    });
};

ProductList.prototype.doFilter = function(filterValue, list) {
    for (var i = 0, len = list.length; i < len; i++) {
        var listItem = list[i];
        var regexp = new RegExp('(' + filterValue + ')+', 'gi');
        var found = regexp.test(listItem.dataset.filtertags);
        if (found) {
            listItem.classList.remove(config.cssClasses.hideListItem);
        } else {
            listItem.classList.add(config.cssClasses.hideListItem);
        }
    }
};

ProductList.prototype.showListItems = function(clicked) {
    if (clicked) {
        var filterValue = clicked.getAttribute('value');
        this.removeClone();
        if (this.isMobile()) {
            this.list.style.display = 'none';
            clicked.parentElement.insertBefore(this.clone, clicked.nextSibling);
            this.doFilter(filterValue, this.clone.querySelectorAll(config.selectors.filterTags));
            this.togglePackages(this.clone);
        } else {
            this.list.style.display = 'block';
            this.doFilter(filterValue, this.element.querySelectorAll(config.selectors.filterTags));
            this.togglePackages(this.element);
        }
    } else {
        // no tabs, no clone (PlayPackages don't have tabs)
        this.togglePackages(this.element);
    }
};

ProductList.prototype.removeClone = function() {
    // Attached nodes have parents. Un-attached doesn't.
    if (this.clone.parentElement) {
        this.clone.parentElement.removeChild(this.clone);
    }
};

ProductList.prototype.isMobile = function() {
    return this.smallScreenWatcher.matches;
};

ProductList.prototype.togglePopoverOnClick = function(currentChannel) {
    var currentElement = currentChannel.currentTarget;
    var wrapperParent = currentElement.parentElement;
    var popover = wrapperParent.getElementsByClassName('channel-popover')[0];

    var hasVisiblePopover = this.element.querySelectorAll('.channel-popover.visible').length;
    var visiblePopover = this.element.querySelector('.channel-popover.visible');

    if (popover.classList.contains('hidden')) {
        popover.classList.remove('hidden');
        popover.classList.add('visible');
    }
    if (hasVisiblePopover > 0) {
        visiblePopover.classList.remove('visible');
        visiblePopover.classList.add('hidden');
    } else {
        popover.classList.remove('hidden');
        popover.classList.add('visible');
    }
    this.setPopoverPlacement(popover, currentElement);
};

ProductList.prototype.setPopoverPlacement = function(popover, currentElement) {
    var channelElement = currentElement;
    var distanceToTop = channelElement.getBoundingClientRect().top;
    var elementHeight = popover.offsetHeight;
    if (elementHeight > screen.height) {
        elementHeight = (screen.height - 20) + 'px';
        popover.style.top = (screen.height + 20) + 'px';
        popover.style.height = elementHeight;
        popover.style.overflow = 'scroll';
    } else if (elementHeight > distanceToTop) {
        popover.style.top = '50%';
        popover.style.transform = 'translate(-50%, -50%)';
    } else {
        popover.style.top = distanceToTop + 'px';
    }
};

ProductList.prototype.closePopoverOnClick = function(e) {
    var popoverCloseBtn = e.currentTarget;
    var popover = popoverCloseBtn.parentElement;
    popover.classList.remove('visible');
    popover.classList.add('hidden');
};

ProductList.prototype.showPopoverOnMouseover = function(currentChannel) {
    var element = currentChannel.currentTarget;
    var wrapperParent = element.parentElement;
    var popover = wrapperParent.getElementsByClassName('channel-popover')[0];
    popover.classList.remove('hidden');
    popover.classList.add('visible');
};

ProductList.prototype.hidePopoverOnMouseout = function(currentChannel) {
    var element = currentChannel.currentTarget;
    var wrapperParent = element.parentElement;
    var popover = wrapperParent.getElementsByClassName('channel-popover')[0];
    popover.classList.remove('visible');
    popover.classList.add('hidden');
};

export default function(params){
    return new ProductList(params)
}
