import * as userService from 'resources/js/services/user-service';
import * as gtmService from 'resources/js/services/gtm-service';

// Initial state
var userData = {
    loggedIn: false,
    doNotTrack: false
};

function isLoggedIn () {
    return userData.loggedIn;
}

function isDoNotTrackSet() {
    var dnt = '';
    if (typeof navigator.doNotTrack !== 'undefined') {
        dnt = navigator.doNotTrack;
    } else if (typeof window.doNotTrack !== 'undefined') {
        dnt = window.doNotTrack;
    } else if (typeof navigator.msDoNotTrack !== 'undefined') {
        dnt = navigator.msDoNotTrack;
    }
    return (dnt === '1' || dnt === 'yes');
}

function initialize(callback) {
    userService.getLoggedIn(function(loggedIn) {

        userData.loggedIn = (loggedIn === 'true');
        userData.doNotTrack = isDoNotTrackSet();

        // Push to GTM
        gtmService.pushToGTM({
            eventName: 'userData',
            customParameters: {
                loggedIn: userData.loggedIn,
                doNotTrack: userData.doNotTrack
            }
        });
        
        if (typeof callback === 'function') callback(userData);
    });
}

export { initialize, isLoggedIn }
