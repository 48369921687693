import *  as debouncerFunction from 'resources/js/utils/debouncer';

const subHeroList = [];

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top > 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
};

const gaImpression = (subhero, index) =>
    debouncerFunction.debouncer(function() {
    if (isInViewport(subhero) && subHeroList[index].hasBeenSeen === false) {
        subHeroList[index].hasBeenSeen = true;
        subHeroImpression(subhero, index);
    }
}, 50);

function subHeroImpression(subHero, index) {
    const currentDataset = subHero.dataset;
    teliaAnalytics.event('Internal Promotion','View', `${currentDataset.discolyticsComponent}_${currentDataset.discolyticsLabel}`, {
        promoa: null,
        promo1nm: currentDataset.discolyticsComponent,
        promo1id: null,
        promo1cr: currentDataset.discolyticsLabel,
        promo1ps: index + 1,
        ni: 1
    })
}

function subHeroClickAnalytic(subHero, index, e) {
    const buttonText= e.target.innerText;
    const currentDataset = subHero.dataset;
    teliaAnalytics.event('Internal Promotion','Click', buttonText, {
        promoa: 'click',
        promo1nm: currentDataset.discolyticsComponent,
        promo1id: null,
        promo1cr: currentDataset.discolyticsLabel,
        promo1ps: index + 1
    })
}

function trackingOfSubHero() {
    const subHeros = Array.prototype.slice.call(document.querySelectorAll('.sub-hero__item'));
    if(subHeros.length > 0) {
        for (let i = 0; i < subHeros.length; i++) {
            subHeroList.push({
                element: subHeros[i],
                hasBeenSeen: false
            })
            if (isInViewport(subHeros[i])) {
                subHeroList[i].hasBeenSeen = true;
                subHeroImpression(subHeros[i], i);
            } else {
                window.addEventListener('scroll', gaImpression(subHeros[i], i));
            }
            subHeros[i].addEventListener('click', (e) => {
                subHeroClickAnalytic(subHeros[i], i, e);
            });
        }
    }
}

function initialize() {
    trackingOfSubHero();
}

export { initialize }
