export default function(name, callback) {
    [].slice.call(document.querySelectorAll('[' + name + ']')).forEach(function(element) {

        var value = element.getAttribute(name) || '';

        element.addEventListener('click', function(e) {
            e.preventDefault();
            callback(value, element);
        });

    });
};
