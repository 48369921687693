import * as areaSwitch from 'resources/js/area-switch';
import modal from 'resources/js/modal';
import * as gtmService from 'resources/js/services/gtm-service';
import * as domHandler from 'resources/js/utils/dom';
import * as inject from 'resources/js/utils/inject';
import endpointsCss from 'resources/js/config/endpoints-css';


export default function() {
    var targetElements = [].slice.call(document.querySelectorAll('[data-logged-in-url]'));

    var requireLoginHandler = function(e) {
        e.preventDefault();
        removeEventListeners();
        requireLogin(e.target);
    };

    var removeEventListeners = function() {
        targetElements.forEach(function(targetElement) {
            targetElement.removeEventListener('click', requireLoginHandler);
        });
    };

    var requireLogin = function(element) {


        require(['loginTemplatePath'], function(loginTemplate) {
            inject.cssFile(endpointsCss.login);

            modal({
                openTrigger: targetElements,
                content: loginTemplate,
                modifier: 'linkmodal__content_login',
                runBeforeOpen: function(target) {
                    window.successfulLogin = function(user) {

                        var targetUrl = target.getAttribute('data-logged-in-url');

                        // Tracking
                        gtmService.push({
                            eventName: 'customEvents',
                            eventAction: 'Click',
                            eventCategory: 'Logged in modal',
                            eventLabel: targetUrl,
                            eventTimeout: 2000,
                            eventCallback: function() {
                                setTimeout(function() {
                                    window.location.href = targetUrl;
                                }, 100);
                            }
                        });

                    };
                },
                runAfterOpen: function() {
                    require('loginScript').then(() => {
                        console.log("loginScript imported!")
                    });
                },
                clickOnTargetAfterInit: element
            });
        })
    };

    targetElements.forEach(function(targetElement) {
        targetElement.addEventListener('click', requireLoginHandler);
    });


    areaSwitch.show('logged-in', 'false');

};
