/**
 * 
 * @param {function} func - callback function
 * @param {number} wait - time delay
 */

const debouncer = (func, wait) => {
	let timeout;
	return function() {
		let context = this, args = arguments;
		let later = function() {
			timeout = null;
			func.apply(context, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export { debouncer }