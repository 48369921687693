import { isIE, isMobile } from './utils/check-different-browsers'
import InitVideoPlayer from './video-player/VideoInit'

const videoPlayers = Array.prototype.slice.call(document.querySelectorAll(".video__wrapper"));

function initialize() {
    for (var i = 0; i < videoPlayers.length; i++) {
        videoPlayers[i].querySelector('video').controls = true;
    }
    //Seek bar needs to be fixed before adding this code and removing the one on line 7 - 9
    // if (isIE() || isMobile()) {
    //     for (var i = 0; i < videoPlayers.length; i++) {
    //         videoPlayers[i].querySelector('video').controls = true;
    //     }
    // } else {
    //     videoPlayers.forEach(function (videoPlayer) {
    //         window.addEventListener('load', () => {
    //             new InitVideoPlayer(videoPlayer);
    //         })
    //     })
    // }
}

export { initialize }

