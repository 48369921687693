import * as heightCorrection from 'resources/js/height-correction';

var areas = {};

function createSwitchElement(domElement) {

    var switchName = domElement.getAttribute('data-area-switch') || '';
    var switchCase = domElement.getAttribute('data-area-switch-case') || '';

    if (switchName.length > 0 && switchCase.length > 0) {

        if (areas[switchName] === undefined) {
            areas[switchName] = [];
        }

        var switchElement = {
            dom: domElement,
            case: switchCase
        };

        areas[switchName].push(switchElement);

    }
}

function show(switchName, switchCase) {
    if (areas[switchName] !== undefined) {
        areas[switchName].forEach(function(element) {
            if (switchCase === element.case) {
                element.dom.style.display = 'block';
            } else {
                element.dom.style.display = 'none';
            }
        });
        heightCorrection.initialize();
    }
}

function initialize() {
    var elements = [].slice.call(document.querySelectorAll('[data-area-switch]'));
    elements.forEach(createSwitchElement);
}


export { initialize, show }
