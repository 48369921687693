var themeContainers = [];
var foregroundElementCollections = [];

var addObjectsTask;
var removeObjectsTasks = [];
var removeObjectsWaits = [];

function initialize() {
    themeContainers = document.querySelectorAll('.custom-theme.animated-theme');

    if (themeContainers.length > 0) {
        const navigationHeader = document.getElementsByClassName('navigation-header')[0];
        const navigationFooter = document.getElementsByClassName('navigation-footer')[0];
        navigationHeader.style.zIndex = '1025';
        navigationFooter.style.zIndex = '1025';

        for (var i = 0; i < themeContainers.length; i++) {
            var container = themeContainers[i];
            container.firstElementChild.classList.add('custom-theme-animated-background');
            var objects = container.getElementsByClassName('animated-foreground-object');
            foregroundElementCollections.push(objects);
        }

        startAnimations();
    }
}

function startAnimations() {
    endAnimations();

    if (themeContainers.length > 0) {
        addAnimatedForegroundObjectsToAllContainers(4);

        addObjectsTask = setInterval(function() {
            addAnimatedForegroundObjectsToAllContainers(4);
        }, 12000);

        scheduleRemovalTasksForAllContainers();
    }
}

function addAnimatedForegroundObjectsToAllContainers(amount) {
    for (var i = 0; i < themeContainers.length; i++) {
        var container = themeContainers[i];
        var duration = calculateAnimationDuration(container);
        addAnimatedForegroundObjectsToContainer(amount, duration, container);
    }
}

function addAnimatedForegroundObjectsToContainer(amount, duration, container) {
    for (var i = 0; i < amount; i++) {
        var element = document.createElement('div');
        element.className = 'animated-foreground-object ' + container.className + ' nr' + i;
        element.style.animationDuration = duration + 's, 5s';
        container.appendChild(element);
    }
}

function scheduleRemovalTasksForAllContainers() {
    for (var i = 0; i < themeContainers.length; i++) {
        var container = themeContainers[i];
        var containerForegroundElements = foregroundElementCollections[i];
        var duration = calculateAnimationDuration(container);
        const waitBeforeStartingRemovalTask = Math.ceil(duration) * 1000 + 7000;
        var wait = setTimeout(function(elements, cont) {
            var interval = setInterval(function() {
                removeAnimatedForegroundObjectsFromContainer(4, elements, cont);
            }, 12000);
            removeObjectsTasks.push(interval);
        }.bind(null, containerForegroundElements, container), waitBeforeStartingRemovalTask);
        removeObjectsWaits.push(wait);
    }
}

function removeAnimatedForegroundObjectsFromContainer(amount, containerForegroundElements, container) {
    var elementsToRemove = [];
    for (var i = 0; i < amount; i++) {
        var element = containerForegroundElements[i];
        if (element) {
            elementsToRemove.push(element);
        }
    }
    for (var j = 0; j < elementsToRemove.length; j++) {
        var elementToRemove = elementsToRemove[j];
        if (elementToRemove) {
            container.removeChild(elementToRemove);
        }
    }
}

function calculateAnimationDuration(container) {
    return container.offsetHeight / 31;
}

function endAnimations() {
    clearInterval(addObjectsTask);
    for (var i = 0; i < removeObjectsWaits.length; i++) {
        clearTimeout(removeObjectsWaits[i]);
    }
    removeObjectsWaits = [];
    for (var j = 0; j < removeObjectsTasks.length; j++) {
        clearInterval(removeObjectsTasks[j]);
    }
    removeObjectsTasks = [];
    clearAllAnimatedForegroundObjects();
}

function clearAllAnimatedForegroundObjects() {
    for (var i = 0; i < themeContainers.length; i++) {
        var container = themeContainers[i];
        var elements = foregroundElementCollections[i];
        removeAnimatedForegroundObjectsFromContainer(elements.length, elements, container);
    }
}

export { initialize, startAnimations, endAnimations }
