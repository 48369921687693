var listeners = {};
var counter = 0;

function create(options) {
    var id = generateId();
    var runOnClick = createEventHandler(id, options);
    listeners[id] = runOnClick;
    document.body.addEventListener('click', runOnClick);
}

function generateId() {
    counter = counter + 1;
    return counter;
}

function createEventHandler(id, options) {

    if (!options.hasOwnProperty('onClickInside') || (options.hasOwnProperty('onClickInside') && typeof options.onClickInside !== 'function')) {
        options.onClickInside = function() {
            remove(id);
        };
    }

    if (!options.hasOwnProperty('onClickOutside') || (options.hasOwnProperty('onClickOutside') && typeof options.onClickOutside !== 'function')) {
        options.onClickOutside = function() {};
    }

    return function(e) {
        if (e.target === options.target || options.target.contains(e.target)) {
            options.onClickInside(function() {
                remove(id);
            });
        } else {
            options.onClickOutside();
            remove(id);
        }
    };

}

function remove(id) {
    document.body.removeEventListener('click', listeners[id]);
    delete listeners[id];
}

export { create }