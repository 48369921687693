import * as debouncerFunction  from 'resources/js/utils/debouncer';

const heroList = [];

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top > 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
};

const gaImpression = (hero, index) =>
    debouncerFunction.debouncer(function() {
    if (isInViewport(hero) && heroList[index].hasBeenSeen === false) {
        heroList[index].hasBeenSeen = true;
        heroImpression(hero, index);
    }
}, 50);

function heroImpression(hero, index) {
    const currentDataset = hero.dataset;
    teliaAnalytics.event('Internal Promotion','View', `${currentDataset.discolyticsComponent}_${currentDataset.discolyticsLabel}`, {
        promoa: null,
        promo1nm: currentDataset.discolyticsComponent,
        promo1id: null,
        promo1cr: currentDataset.discolyticsLabel,
        promo1ps: index + 1,
        ni: 1
    })
}

function heroClickAnalytic(hero, index, e) {
    const buttonText= e.target.innerText;
    const currentDataset = hero.dataset;
    teliaAnalytics.event('Internal Promotion','Click', buttonText, {
        promoa: 'click',
        promo1nm: currentDataset.discolyticsComponent,
        promo1id: null,
        promo1cr: currentDataset.discolyticsLabel,
        promo1ps: index + 1
    })
}

function trackingOfHero() {
    const heros = Array.prototype.slice.call(document.querySelectorAll('.hero'));
    if (heros.length > 0) {
        for (let i = 0; i < heros.length; i++) {
            heroList.push({
                element: heros[i],
                hasBeenSeen: false
            })
            if (isInViewport(heros[i])) {
                heroList[i].hasBeenSeen = true;
                heroImpression(heros[i], i);
            } else {
                window.addEventListener('scroll', gaImpression(heros[i], i));
            }
            heros[i].addEventListener('click', (e) => {
                heroClickAnalytic(heros[i], i, e);
            });
        }
    }
}

function initialize() {
    trackingOfHero();
}

export { initialize }
