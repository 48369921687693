
function getConsentList(consents, callback) {
    if (window.websiteCookieConsent) {
        getConsent(consents, {}, callback);
    }
}

function getConsent(list, obj, callback) {
    if (list.length == 0) {
        callback(obj);
    } else {

        var consent = list.pop();
        var consentName = Object.keys(consent)[0];

        window.websiteCookieConsent.get(consentName).then(function() {
            obj[consentName] = true;
            getConsent(list, obj, callback);
        }, function() {
            obj[consentName] = false;
            getConsent(list, obj, callback);
        });

    }
}

export { getConsentList }