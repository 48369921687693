import * as gtmService from 'resources/js/services/gtm-service';

function bindAccordionItemEvents(nodeItem) {

    var heading = nodeItem.querySelector('[data-accordion-heading]');

    heading.addEventListener('click', toggleAccordionItem);

    heading.addEventListener('keypress', function(event) {
        if (event.keyCode === 13) {
            toggleAccordionItem(event);
        }
    });

}

function toggleAccordionItem(event) {

    var currentHeading = event.currentTarget;
    var contentElement = event.currentTarget.nextElementSibling;

    var trackingObj = {
        eventName: 'customEvents',
        eventCategory: 'Accordion',
        eventAction: 'Opened',
        eventLabel: currentHeading.innerText
    };

    if (currentHeading.getAttribute('aria-expanded') === 'true') {
        trackingObj.eventAction = 'Closed';
        currentHeading.setAttribute('aria-expanded', 'false');
        contentElement.setAttribute('aria-hidden', 'true');
        contentElement.removeAttribute('tabindex');
    } else {
        trackingObj.eventAction = 'Opened';
        currentHeading.setAttribute('aria-expanded', 'true');
        contentElement.setAttribute('aria-hidden', 'false');
        contentElement.setAttribute('tabindex', '0');
    }

    contentElement.classList.toggle('accordion-item__body--collapsed');
    currentHeading.firstElementChild.children[1].classList.toggle('accordion-item__icon_rotate-180');
    currentHeading.firstElementChild.classList.toggle('accordion-item__heading--selected');

    gtmService.push(trackingObj);

}

function initialize() {
    [].slice.call(document.querySelectorAll('[data-accordion-component]')).forEach(function(accordion) {
        console.debug('Created Accordion component');
        [].forEach.call(accordion.children, bindAccordionItemEvents);
    });
}

export { initialize }
