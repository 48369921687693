import configEndpoints from 'resources/js/config/endpoints';
import xhttp from 'resources/js/utils/xhttp';
import * as logService from 'resources/js/services/log-service';

function getLoggedIn(onSuccess, onError) {
    xhttp({
        method: 'GET',
        url: configEndpoints.userInteractionLoggedIn,
        success: function(response) {
            var userLoggedIn = response || '';
            onSuccess(userLoggedIn);
        },
        error: function(status) {
            logService.error('User service is not available (response code: ' + status + ')');
        }
    });
}

export { getLoggedIn }
