'use strict';

import "resources/less/discover.less";
import "core-js/modules/es6.promise";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.symbol";

import * as backgroundOptimizer from "resources/js/background-optimizer";
import * as heightCorrection from "resources/js/height-correction";
import * as contentSwitch from "resources/js/content-switch";
import * as countDown from "resources/js/count-down";
import * as userData from "resources/js/user-data";
import * as areaSwitch from "resources/js/area-switch";
import * as shortcuts from "resources/js/shortcuts";
import * as accordion from "resources/js/accordion";
import * as pagesection from "resources/js/page-section";
import * as callguideChatTrigger from "resources/js/callguide-chat-trigger";
import * as modalYoutube from "resources/js/modal-youtube";
import * as modalInjected from "resources/js/modal-injected";
import * as moafInject from "resources/js/moaf-inject-resources";
import * as stickyTable from "resources/js/sticky-table";
import * as consentUtils from "resources/js/utils/consent";
import * as productlist from "resources/js/legacy/productlist";
import * as priceList from "resources/js/price-list";
import * as responsiveTabs from "resources/js/responsive-tabs";
import * as search from "resources/js/search/search";
import * as coolWrapper from "resources/js/event-wrapper";
import * as themes from "resources/js/themes";
import * as videoPlayer from "resources/js/init-video-player"
import * as webAnalyticsLabeler from "resources/js/webanalytics-labeler";
import * as subHero from "resources/js/sub-hero";
import * as heroAnalytics from "resources/js/hero-analytics";

import PolyfillClosest from "resources/js/utils/polyfill-closest";

import startLoggedIn from 'resources/js/start-logged-in';
import startLoggedOut from 'resources/js/start-logged-out';

require([
    '../node_modules/@telia/riot-banner-component/riot-banner-component/resources/js/riot-banner-component.js'
], function(){
})

PolyfillClosest();

var applyConsent = function() {

    var consentList = [{
        'youtube-video': 3
    },
    {
        recommendation: 3
    }
    ];

    consentUtils.getConsentList(consentList, function(consents) {

        // video.initialize(consents['youtube-video']);
        modalYoutube.initialize(consents['youtube-video']);

        userData.initialize(function(userData) {
            var user = userData;
            if (user.loggedIn) {
                startLoggedIn(user, consents);
            } else {
                startLoggedOut();
            }
        });

    });
};

window.addEventListener('website-cookie-consent-changed', function() {
    applyConsent();
});

document.addEventListener('visibilitychange', function() {
    if (document.hidden) {
        themes.endAnimations();
    } else {
        themes.startAnimations();
    }
});

function onLoad() {
    backgroundOptimizer.initialize();
    webAnalyticsLabeler.initialize();
    shortcuts.initialize();
    accordion.initialize();
    callguideChatTrigger.initialize();
    contentSwitch.initialize();
    countDown.initialize();
    areaSwitch.initialize();
    pagesection.initialize();
    stickyTable.initialize();
    search.initialize();
    coolWrapper.initialize();
    moafInject.initialize();
    priceList.initialize();
    heightCorrection.initialize();
    themes.initialize();
    responsiveTabs.initialize();
    applyConsent();
    videoPlayer.initialize();
    subHero.initialize();
    heroAnalytics.initialize();
}

if (document.readyState === 'loading') { // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', onLoad);
} else { // `DOMContentLoaded` has already fired
    onLoad();
}

window.addEventListener('load', () => {
    productlist.initialize();
    heightCorrection.initialize();
    modalInjected.initialize();
});