import * as mediaQueries from 'resources/js/utils/media-queries';
import * as screenHandler from 'resources/js/utils/screen';

var timer;
var resizing = false;
var screenWidth = screenHandler.getScreenWidth();
var elements = [];

function createElementFromDom(domElement) {
    var breakpoint = mediaQueries.get(domElement.getAttribute('data-breakpoint'));
    return {
        imageSmall: domElement.hasAttribute('data-image-small') ? domElement.getAttribute('data-image-small') : '',
        imageLarge: domElement.hasAttribute('data-image-large') ? domElement.getAttribute('data-image-large') : '',
        breakpoint: (breakpoint !== null) ? breakpoint : mediaQueries.get('mqMedium'),
        dom: domElement
    };
}

function optimize(element) {
    if (!isNaN(screenWidth) && (screenWidth <= element.breakpoint)) {
        element.dom.style.backgroundImage = "url('" + element.imageSmall + "')";
    } else {
        element.dom.style.backgroundImage = "url('" + element.imageLarge + "')";
    }
}

function initialize() {
    elements = [].slice.call(document.querySelectorAll('[data-background-optimizer]')).map(function(domElement) {
        var element = createElementFromDom(domElement);
        optimize(element);
        return element;
    });
    window.addEventListener('resize', resize);
}

function resize() {
    if (!resizing) {

        resizing = true;
        var currentScreenWidth = screenHandler.getScreenWidth();

        if (currentScreenWidth === screenWidth) {
            resizing = false;
            return false;
        }

        clearTimeout(timer);
        timer = setTimeout(function() {
            screenWidth = screenHandler.getScreenWidth();
            elements.forEach(optimize);
            resizing = false;
        }, 300);

    }
}

export { initialize }
