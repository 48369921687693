
function setUrlHash(hash) {
    location.hash = '!' + hash;
}

function getHashArrayFromUrl() {
    return (location.hash.slice(2) || "").split("&");
}

function getValueFromHashArray(parameter, hashArray) {
    if (hashArray.length === 1 && hashArray[0].split("=").length === 1) {
        return hashArray[0];
    }
    return hashArray.reduce(function(value, pairStr) {
        var pairArray = pairStr.split("=");
        if ((pairArray[0] || "") === parameter) {
            return pairArray[1] || "";
        }
        return value;
    }, "");
}

function updateHashArray(parameters, selectedParameter, newValue) {
    var hashArray = getHashArrayFromUrl();
    return parameters.reduce(function(sum, parameter) {
        if (parameter == selectedParameter) {
            sum.push(parameter + "=" + newValue);
        } else {
            var currentValue = getValueFromHashArray(parameter, hashArray);
            if (currentValue) {
                sum.push(parameter + "=" + currentValue);
            }
        }
        return sum;
    }, []);
}

export {
    setUrlHash,
    getHashArrayFromUrl,
    getValueFromHashArray,
    updateHashArray
};

