import * as urlhash from 'resources/js/utils/urlhash';
import selectList from 'resources/js/select-list';


var componentNames = [];

function getURLHash(componentName) {
    return urlhash.getValueFromHashArray(componentName, urlhash.getHashArrayFromUrl());
}

function setURLHash(componentName, newHash) {
    if (componentNames.length < 2) {
        urlhash.setUrlHash(newHash);
        return;
    }
    var hashString = urlhash.updateHashArray(componentNames, componentName, newHash).join("&");
    urlhash.setUrlHash(hashString);
}

function buildTabs(tabContainer, tabClickCallback) {

    var tabLabels = tabContainer.querySelectorAll(".disco-responsive-tabs__tabs li > button:first-child");

    return [].slice.call(tabContainer.querySelectorAll(".disc-tab-system__tabcontent")).map(function(element, idx) {
        tabLabels[idx].addEventListener('click', function() {
            tabClickCallback(element.id);
        });
        return {
            id: element.id,
            show: function() {
                tabLabels[idx].classList.add("active");
                element.classList.remove("hidden");
            },
            hide: function() {
                tabLabels[idx].classList.remove("active");
                element.classList.add("hidden");
            }
        };
    });
}


function buildTabComponent(tabContainer, idx) {

    var componentName = tabContainer.getAttribute('data-name') || idx;
    var tabs = buildTabs(tabContainer, tabClick);

    var dropdown = selectList({
        element: tabContainer,
        onSelect: function(selectedId) {
            tabClick(selectedId);
        }
    });

    function tabClick (tabId) {
        setURLHash(componentName, tabId);
        window.teliaAnalytics.event('VirtualPageView', 'Click', tabId);
    }

    function setTab (tabId) {
        var activeTab = tabs.filter(function(tab) {
            if (tabId === tab.id) {
                return true;
            }
            tab.hide();
            return false;
        })[0] || (tabs[0] || null);

        if (activeTab) {
            activeTab.show();
            if (dropdown.getSelected().id !== activeTab.id) {
                dropdown.select(activeTab.id);
            }
        }
    }

    window.addEventListener("hashchange", function() {
        setTab(getURLHash(componentName));
    });

    setTab(getURLHash(componentName));
    tabContainer.classList.remove("hidden");

    return componentName;

}

function initialize() {
    componentNames = [].slice.call(document.querySelectorAll('.disc-tab-system')).map(buildTabComponent);
}

export { initialize }