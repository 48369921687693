import * as inject from 'resources/js/utils/inject';
import endpoints from 'resources/js/config/endpoints';
import endpointsCss from 'resources/js/config/endpoints-css';

function initialize() {
    if (document.querySelectorAll('search-result, search-bar, header-nav').length) {
        inject.javascriptFile(endpoints.searchFrontend);
        inject.javascriptFile(endpoints.searchFrontendVendor);
        inject.cssFileOnce(endpointsCss.search);
    }
}

export { initialize }