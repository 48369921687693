import * as domUtils from 'resources/js/utils/dom';
import modal from 'resources/js/modal';

function createTable(tableWrapper) {

    var pagination = tableWrapper.querySelector('[data-table-pagination]');
    var paginationDots = [].slice.call(pagination.querySelectorAll('.table-pagination__dot'));

    var stickyBar = tableWrapper.querySelector('[data-table-stickybar]');
    var stickyHeadWrapper = stickyBar.querySelector('[data-table-stickyhead-wrapper]');
    var stickyHead = stickyHeadWrapper.querySelector('[data-table-stickyhead]');

    var grid = tableWrapper.querySelector('[data-table-grid]');
    var head = grid.querySelector('[data-table-head]');
    var body = grid.querySelector('[data-table-body]');

    var oneCell = head.querySelector('.table-cell');
    var columnMargin = 0;
    var columnWidth = 176;

    if (oneCell) {
        columnMargin = domUtils.cssToPx(window.getComputedStyle(oneCell).getPropertyValue('margin-left'));
        columnWidth = oneCell.offsetWidth + (columnMargin * 2);
    }

    var table = {
        wrapper: tableWrapper,
        stickyBar: stickyBar,
        stickyHeadWrapper: stickyHeadWrapper,
        stickyHead: stickyHead,
        grid: grid,
        head: head,
        body: body,
        responsiveGutters: [].slice.call(tableWrapper.querySelectorAll('.table-gutter--responsive-width')),
        columns: [].slice.call(head.querySelectorAll('.table-cell--head')).length || 0,
        columnLabel: [].slice.call(head.querySelectorAll('.table-cell--head-label')).length || 0,
        columnWidth: columnWidth,
        selectedColumn: 0,
        pagination: pagination,
        paginationDots: paginationDots
    };

    table.setWidth = function(width, suffix) {
        this.stickyHead.style.width = width + suffix;
        this.head.style.width = width + suffix;
        this.body.style.width = width + suffix;
    };

    table.calculateCollapsePos = function() {
        var bodyTop = this.body.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);
        this.collapsePos = bodyTop - (this.stickyHead.offsetHeight + this.pagination.offsetHeight);
    };

    table.setStickyMargins = function(margin, suffix) {
        this.stickyBar.style.marginBottom = margin + suffix;
        this.grid.style.marginTop = (margin > 0) ? '-' + margin + suffix : '';
    };

    table.setPagination = function() {
        var scrollSpace = this.grid.scrollWidth - this.grid.clientWidth;
        if (scrollSpace > 0) {
            this.pagination.classList.add('table-pagination--visible');
            this.stickyHeadWrapper.style.top = (this.pagination.offsetHeight - 16) + 'px';
            this.setStickyMargins((this.stickyHead.offsetHeight - 16), 'px');
        } else {
            this.pagination.classList.remove('table-pagination--visible');
            this.stickyHeadWrapper.style.top = this.pagination.offsetHeight + 'px';
            this.setStickyMargins(this.stickyHead.offsetHeight, 'px');
        }
    };

    table.setHorizontalScroll = function() {

        table.stickyHeadWrapper.scrollLeft = this.scrollLeft;

        var scrollSpace = (table.grid.scrollWidth - table.grid.clientWidth);
        var newSelectedColumn = (table.grid.scrollLeft >= scrollSpace) ? table.columns - 1 : Math.floor((table.grid.scrollLeft + (table.columnWidth * 0.5)) / table.columnWidth);

        table.paginationDots[table.selectedColumn].classList.remove('table-pagination__dot--selected');
        table.paginationDots[newSelectedColumn].classList.add('table-pagination__dot--selected');
        table.selectedColumn = newSelectedColumn;

    };

    table.checkCollapse = function(scrollPos) {
        var collapsePos = this.collapsePos;
        if (scrollPos < collapsePos) {
            if (this.isCollapsed === false) {
                return;
            }
            this.isCollapsed = false;
            this.stickyBar.classList.remove('table-stickybar--collapsed');
        } else if (scrollPos >= collapsePos) {
            if (table.isCollapsed) {
                return;
            }
            this.isCollapsed = true;
            this.stickyBar.classList.add('table-stickybar--collapsed');
        }
    };

    return table;

}

function makeStickyHandler(tables, oldStickyHandler) {

    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // No changes - return old stickyHandler
    if (windowWidth == (oldStickyHandler.currentWindowWidth || 0)) {
        return oldStickyHandler;
    }

    var stickyTables = filterStickyTables(tables);

    var newStickyHandler = {

        currentWindowWidth: windowWidth,
        tables: stickyTables,

        checkCollapse: (function(stickyTables) {
            return function(e) {
                var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
                stickyTables.forEach(function(table) {
                    table.checkCollapse(scrollPos);
                });
            };
        }(stickyTables)),

        documentHeightChanged: function() {
            var me = this;
            if (me.recalculateTimer) {
                clearTimeout(me.recalculateTimer);
            }
            me.recalculateTimer = setTimeout(function() {
                me.tables.forEach(function(table) {
                    table.calculateCollapsePos();
                });
                me.checkCollapse();
            }, 200);
        }

    };

    window.removeEventListener('scroll', oldStickyHandler.checkCollapse);
    if (stickyTables.length > 0) {
        window.addEventListener('scroll', newStickyHandler.checkCollapse);
    }

    return newStickyHandler;

}

function filterStickyTables(tables) {
    return tables.filter(function(table) {

        var newTableWidth = table.columnWidth * (table.columns + 2);
        var tableWidth = table.wrapper.offsetWidth;
        // Mobile
        if (tableWidth <= (table.columnWidth * (table.columns + table.columnLabel))) {

            table.grid.addEventListener('scroll', table.setHorizontalScroll, true);
            table.wrapper.classList.remove('table--desktop');
            table.setWidth((Math.max(tableWidth, table.columnWidth * table.columns + 0)), 'px');
            table.setPagination();
            table.calculateCollapsePos();
            table.setHorizontalScroll();

            return true;


        } else { // Desktop

            table.grid.removeEventListener('scroll', table.setHorizontalScroll);
            table.wrapper.classList.add('table--desktop');
            table.setWidth('', '');
            table.setStickyMargins('', '');

            var responsiveGutterWidth = Math.min(Math.max(table.columnWidth - (newTableWidth - tableWidth), 0), table.columnWidth);
            table.responsiveGutters.forEach(function(gutter) {
                gutter.style.minWidth = responsiveGutterWidth + 'px';
            });

            return false;

        }
    });
}


function initialize() {

    var tables = [].slice.call(document.querySelectorAll('[data-table]')).map(createTable);

    tables.map(function(table) {
        table.grid.addEventListener('click', function(event) {
            if (event.target.classList.value.indexOf('table-gutter__info') > -1) {
                var thisModal = modal({
                    openTrigger: [],
                    content: '<div style="padding: 3.2rem">' + event.target.dataset.infoText + '</div>',
                    modifier: 'linkmodal__content_modal',
                    closeButtonColor: 'black',
                    clickOnTargetAfterInit: true,
                    runAfterOpen: function(target, modal, closeTarget) {},
                    runAfterClose: function() {
                        this.modalElement.parentElement.removeChild(this.modalElement);
                        thisModal.destroy();
                    }
                });
            }
        });
    });

    if (tables.length) {
        var stickyHandler = makeStickyHandler(tables, {});

        window.addEventListener('resize', function() {
            stickyHandler = makeStickyHandler(tables, stickyHandler);
            stickyHandler.checkCollapse();
        });

        domUtils.createDocumentHeightChangeListenerDOMNode().addEventListener('resize', function() {
            stickyHandler.documentHeightChanged();
        });

        stickyHandler.checkCollapse();
    }

}

export { initialize }