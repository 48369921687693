import * as domUtils from 'resources/js/utils/dom';
function initialize() {
    var containers = document.body.querySelectorAll('[data-event-wrapper="flashlight"] .flashlight-container');

    [].slice.call(containers).forEach(function(container) {
        var flashlight = container.querySelector('.flashlight-searchlight');
        var timeout;

        var topOffset = container.offsetTop;
        flashlight.style.top = -(5150 + topOffset) + 'px';

        var moveEventListener = function(event) {
            flashlight.style.transform = 'translate3d(' + (event.pageX) + 'px,' + (event.pageY) + 'px' + ',0)';
        }

        var enterEventHandler = function() {
            topOffset = container.offsetTop;
            flashlight.style.top = -(5150 + topOffset) + 'px';
            if (timeout) {
                clearTimeout(timeout);
            }
        }

        var leaveEventHandler = function(event) {
            var e = event.toElement || event.relatedTarget;
            if (e == null || e.parentNode == this || e == this) {
                return;
            }

            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(function() {
                randomSearch(container);
            }, 1000);
        }

        var revealEventListener = function() {
            flashlight.classList.add('flashlight-searchlight_off');
            this.removeEventListener('click', revealEventListener);
        }

        var flashLightTransitionend = function(event) {
            this.parentElement.removeChild(this);
            this.removeEventListener('click', flashLightTransitionend);
        }

        container.addEventListener('mousemove', moveEventListener);
        container.addEventListener('mouseenter', enterEventHandler)
        container.addEventListener('mouseout', leaveEventHandler)
        container.addEventListener('click', revealEventListener)

        flashlight.addEventListener("transitionend", flashLightTransitionend);

        var randomSearch = function(container) {
            var x = Math.floor((Math.random()) * Math.floor(container.offsetWidth));
            var y = Math.floor((Math.random()) * Math.floor(container.offsetHeight)) + topOffset;

            move(flashlight, x, y);
        }


        var move = function(element, x1, y1) {
            var translate3d = domUtils.getTranslate3d(flashlight);

            var x = parseInt(translate3d[0]);
            var y = parseInt(translate3d[1]);
            var deltaX = x1 - x;
            var deltaY = y1 - y;
            var count = (Math.floor(Math.random() * 150) + 40);
            var delay = 20;

            var i = 1;

            function next() {
                var nextX = (x + (deltaX * i / count));
                var nextY = (y + (deltaY * i / count));

                if (i >= count) {
                    clearTimeout(timeout);
                    timeout = setTimeout(function() {
                        randomSearch(container);
                    }, Math.floor(Math.random() * 1000));
                    return;
                }

                i++;
                element.style.transform = 'translate3d(' + nextX + 'px,' + nextY + 'px' + ',0)';
                timeout = setTimeout(next, delay);
            }

            next();
        }

        timeout = setTimeout(function() {
            randomSearch(container)
        }, 2000);
    })
}

export { initialize }