export default {
    searchQueryCompletion: '/services/discover/rest/discover-search/v2/queryCompletion',
    searchQuery: '/services/discover/rest/discover-search/v2/search',
    commonPrivaryFilterNames: '/services/common/rest/common-privacy/v2/filter-names',

    // User interaction (https://diva.teliasonera.net/stash/projects/DC-DISC/repos/user-interaction/browse)
    userInteractionLoggedIn: '/.api/springfield-login/login/isloggedin',

    // Logger has been moved to Springfield: https://diva.teliacompany.net/bitbucket/projects/DCCOMMON/repos/frontend-logger-service/browse
    // http://k8slb03t.ddc.teliasonera.net/frontend-logger-service/v1/log

    // Search frontend
    searchFrontend:'/.resources/discover/resources/js/search-script.js',
    searchFrontendVendor: '/.resources/discover/resources/js/vendor~search-script.js',
    searchEndpoint: '/.api/search-api/v1/search',
}

/*
TODO:
'login-modal': '/resources/discover/resources/js/login.min',
'login-template': '/resources/discover/resources/js/loginTemplate',
'search-fe': '/.resources/discover/resources/js/disco-search.min'
*/
