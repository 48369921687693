import * as objectUtils from 'resources/js/utils/object';
import endpointsJsLibs from 'resources/js/config/endpoints-jslibs';

var injectedFiles = [];

function injectOnce(URI, injectFunction) {
    if (URI.length > 0 && injectedFiles.indexOf(URI) == -1) {
        injectedFiles.push(URI);
        injectFunction(URI);
    }
}

function cssFileOnce(URI) {
    injectOnce(URI, cssFile);
}

function javascriptFileOnce(URI) {
    injectOnce(URI, javascriptFile);
}

function javascriptFile(URI) {
    var script = document.createElement('script');
    script.src = URI;
    document.body.appendChild(script);
}

function cssFile(URI) {
    var link = document.createElement('link');
    link.href = URI;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(link);
}

function resourcesFromMarkupAttributes(params) {

    var injectedMarkup = params.injectedMarkup.parentNode.querySelector('[data-dependency-js-lib]');
    if (injectedMarkup == null) {
        console.debug('No external markup with correct attributes injected');
        return;
    }

    var css = injectedMarkup.getAttribute('data-dependency-css') ||  '';
    if (css.length > 0 && injectedFiles.indexOf(css) == -1) {
        cssFileOnce(css);
        injectedFiles.push(css);
    }

    var js = injectedMarkup.getAttribute('data-dependency-js') ||  '';

    if (js.length > 0 && injectedFiles.indexOf(js) == -1) {
            setTimeout(function() {
                javascriptFileOnce(js);
                injectedFiles.push(js);
            }, 100);
    }

    if (typeof params.callback === 'function') params.callback(injectedMarkup);

}

export {
    cssFile,
    cssFileOnce,
    javascriptFile,
    javascriptFileOnce,
    resourcesFromMarkupAttributes
}
