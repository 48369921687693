import { logFE } from '@telia/fe-logger-service';

function log(type, message, detailedMessage) {
    if (!detailedMessage) {
        detailedMessage = '';
    }
    logFE({
        'message': (typeof detailedMessage === 'object') ? JSON.stringify(detailedMessage) : String(detailedMessage),
        'title': message,
        'logLevel': type
    })
    
}

function error(message, errorMsg) {
    log('ERROR', message, errorMsg);
}

function warn(message, warningMsg) {
    log('WARN', message, warningMsg);
}

function trace(message, traceMsg) {
    log('TRACE', message, traceMsg);
}

function debug(message, debugMsg) {
    log('DEBUG', message, debugMsg);
}

function info(message, infoMsg) {
    log('INFO', message, infoMsg);
}

export { error, warn, trace, debug, info }