import ProductList from 'resources/js/productlist.class'

function initialize() {
    var initiateHeightCorrection = function(elements) {
        if (elements.length) {
            var previousRow = [];
            var offsetTop = 0;
            var previousRowHeight = 0;

            elements.forEach(function(element) {
                if (element.offsetTop > offsetTop) {
                    setHeightOnElements(previousRow, previousRowHeight);
                    offsetTop = element.offsetTop;
                    previousRowHeight = 0;
                    previousRow = [];
                }

                if (element.offsetHeight > previousRowHeight) {
                    previousRowHeight = element.offsetHeight;
                }

                previousRow.push(element.firstElementChild.firstElementChild);
            });

            setHeightOnElements(previousRow, previousRowHeight);
        }

        function setHeightOnElements(elements, height) {
            elements.forEach(function(element) {
                element.style.height = height + 'px';
            });
        }
    };

    var filterLists = [...document.querySelectorAll('[data-widget="list-container"]')];
    var productLists = [];
    for (var i = 0, len = filterLists.length; i < len; i++) {
        productLists.push(ProductList(filterLists[i]));
    }

    var heightCorrectionList = [...document.querySelectorAll('[data-widget="list-container"] .tsProductList--Alt li.tsProductList-Item')];
    initiateHeightCorrection(heightCorrectionList);
    return productLists;
}


export { initialize }
