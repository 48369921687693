import xhttp from 'resources/js/utils/xhttp';
import * as inject from 'resources/js/utils/inject';
import polyfillCustomEvent from 'resources/js/utils/polyfill-custom-event';
import modalComponent from 'resources/js/modal';
import endpointsJsLibs from 'resources/js/config/endpoints-jslibs';

function initialize() {
    let clickHandler = function(event) {
        let target = this;
        if ("dontOpenModal" in target.dataset && target.dataset.dontOpenModal === "false") {
            return true;
        }
        event.preventDefault();
        target.removeEventListener('click', clickHandler);
        let url = target.getAttribute('data-inject-modal') || '';
        console.debug('markup-url: ', url);
        xhttp({
            method: 'GET',
            url: url,
            error: function(status) {
                console.debug(status);
            },
            success: function(responseHtml) {
                modalComponent({
                    openTrigger: [target],
                    content: responseHtml || '',
                    closeButtonColor: 'black',
                    modifier: 'linkmodal__content_modal',
                    clickOnTargetAfterInit: true,
                    runAfterOpen: runAfterOpenModal
                });
            }
        });
    };

    // For some reason the querySelectorAll don't always return a result, even though the DOM should be loaded
    // and the readyState is 'interactive'. I.E. Everything SHOULD be ready.
    // Therefor we need a timeout, 100 ms seems to be enough.
    let targetElements = [...document.querySelectorAll('[data-inject-modal]')];
    if (targetElements.length > 0) {
        /*
        Polyfill to Custom Event,
        see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Browser_compatibility
        */
        polyfillCustomEvent();
        targetElements.forEach(function(target) {
            target.addEventListener('click', clickHandler, false);
        });
    }
}

function runAfterOpenModal(target, modal, closeTarget) {
    let injectedMarkup = modal.parentNode.querySelector('.linkmodal [data-dependency-js-lib]');
    if (injectedMarkup == null) {
        console.debug('No external markup with correct attributes injected');
        return;
    }
    // Inject css
    let cssFileURI = injectedMarkup.getAttribute('data-dependency-css') ||  '';
    inject.cssFileOnce(cssFileURI);
    require(['angular'], function(angular) {
        if (injectedMarkup.dataset.dependencyJsLib === 'vue') {
            inject.javascriptFileOnce(endpointsJsLibs.vue);
        }
        // Inject js
        let javascriptFileURI = injectedMarkup.getAttribute('data-dependency-js') ||  '';
        inject.javascriptFileOnce(javascriptFileURI);
        // Listen for close event
        injectedMarkup.addEventListener('close', function closeFunction(event) {
            this.removeEventListener('close', closeFunction);
            closeTarget();
        });
        // Send open event
        // CustomEvent-polyfill should be injected
        document.body.dispatchEvent(new CustomEvent('discover-open-injected'));
    });
}

export { initialize }
