export default function(options) {
    var settings = {
        method: options.method || 'GET',
        url: options.url || '',
        data: options.data || '',
        success: options.success || function() {},
        error: options.error || function() {},
        json: options.json || false
    };

    var xhttp = new XMLHttpRequest();
    xhttp.open(settings.method, settings.url, true);
    if (settings.json) {
        xhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        settings.data = JSON.stringify(settings.data);
    }
    xhttp.onload = function() {
        if (xhttp.readyState === 4) {
            if (xhttp.status === 200) {
                settings.success(xhttp.responseText);
            } else {
                settings.error(xhttp.status);
            }
        }
    };

    xhttp.send(settings.data);

};