import dataAttributeAction from 'resources/js/data-attribute-action';
import * as areaSwitch from 'resources/js/area-switch';

export default function(user, consents) {

    dataAttributeAction('data-logged-in-url', function(url) {
        window.location.href = url;
    });

    areaSwitch.show('logged-in', 'true');

};
