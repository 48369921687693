import * as inject from 'resources/js/utils/inject'
import endpointsJsLibs from 'resources/js/config/endpoints-jslibs';


/*
    Tight coupling to template definition: customer-interaction:moaf-on-customer-interaction-template/moaf-on-customer-interaction-template
    Angular is hardcoded in the markup when
*/

function loadRequiredLibs(injectables) {
    let angularRequired = false
    let vueRequired = false
    injectables.forEach(el => {
        if(el.dataset && el.dataset.dependencyJsLib === 'vue') {
            vueRequired = true
            inject.javascriptFileOnce(endpointsJsLibs.vue)
        } else {
            angularRequired = true
            inject.javascriptFileOnce(endpointsJsLibs.angular)
        }
    })
    return {angularRequired, vueRequired}
}
    

function loadMoafs() {
    [].slice.call(document.querySelectorAll('[data-dependency-js], [data-dependency-css]')).forEach(el => {
        inject.resourcesFromMarkupAttributes({
            injectedMarkup: el
        });
    });
}

function initialize() {
    const injectables = document.querySelectorAll('[data-dependency-js], [data-dependency-css]')
    if (injectables.length) {
        const libRequirements = loadRequiredLibs([].slice.call((injectables)))

        let retries = 50
        let interval = setInterval(function () {
            const angularLoaded = !libRequirements.angularRequired || typeof window.angular !== "undefined"
            const vueLoaded = !libRequirements.vueRequired || typeof window.Vue !== "undefined"
            if (angularLoaded && vueLoaded && retries > 0) {
                loadMoafs()
                clearInterval(interval);
            }
            retries--;
        }, 500)
    }
}

export {initialize}
