import * as domUtil from 'resources/js/utils/dom';

var bodyElement = document.body;
var modules;

function getServerTime(createCountDownModuleCallback, startCountDownCallback, element) {
    var countDownObj;
    var xhttp = new XMLHttpRequest();
    var param = '?no-cache=' + new Date().getTime();
    xhttp.open('GET', '/api/1.0/servertime' + param, true);
    // xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhttp.onload = function() {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
            var response = xhttp.responseText.trim();
            if (response) {
                countDownObj = createCountDownModuleCallback(element, response);
            }
        } else if (xhttp.status === 404) {
            countDownObj = createCountDownModuleCallback(element, new Date().toISOString());
        }
        startCountDownCallback(countDownObj);
    };
    xhttp.send();
}

function getTimeRemaining(servertime) {
    var t = servertime;
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}

function setCountDownMarkUp(clock, t) {
    clock.daysSpan.innerHTML = ('0' + t.days).slice(-2);
    clock.hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    clock.minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    clock.secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
}

function startCountDown(clock) {
    var initialDate = new Date().getTime();
    var iteration = 0;
    var currentTime = clock.endTime - clock.serverTime;
    var runCountDown = currentTime > 0 && clock.maxIterations > 0;


    function updateCountDown() {
        var currentDate = new Date().getTime();

        iteration = iteration + 1;
        var diff = (currentDate - initialDate) - (iteration * clock.step);
        var remainingTime = currentTime - (iteration * clock.step);

        if (iteration >= clock.maxIterations) {
            location.reload();
        } else if (diff > clock.step || diff < 0) {
            /* *SYNKA OM, NÅGOT ÄR SKUMT* */
            getServerTime(createCountDown, startCountDown, clock.element);
        } else {
            setCountDownMarkUp(clock, getTimeRemaining(remainingTime));
            setTimeout(updateCountDown, (clock.step - diff));
        }
    }

    if (runCountDown) {
        console.debug('Start counting down ...')
        setTimeout(function() {
            domUtil.removeClass(clock.element, 'count-down__hidden');
            updateCountDown();
        }, clock.step);

    } else {
        domUtil.removeClass(clock.element, 'count-down__hidden');
        setCountDownMarkUp(clock, getTimeRemaining(0));
    }
}

function createCountDown(element, serverTimeParam) {
    var maxIterations;
    var step = 1000;
    var additionalDelay = 5000;
    var endTime = new Date(element.getAttribute('data-count-down-module-date-time')).getTime() + additionalDelay;
    var serverTime = new Date(serverTimeParam).getTime();
    if (serverTime && (endTime > new Date().getTime())) {
        maxIterations = (endTime - serverTime) / step;
    } else {
        maxIterations = 0;
    }

    return {
        element: element,
        endTime: endTime,
        serverTime: serverTime,
        maxIterations: maxIterations,
        step: step,
        daysSpan: element.querySelector('[data-count-down-module-days]'),
        hoursSpan: element.querySelector('[data-count-down-module-hours]'),
        minutesSpan: element.querySelector('[data-count-down-module-minutes]'),
        secondsSpan: element.querySelector('[data-count-down-module-seconds]')
    };
}

function initialize() {
    modules = [].slice.call(bodyElement.querySelectorAll('[data-count-down-module]')).map(function(scopeElement) {
        console.debug('Create count-down component')
        var module = getServerTime(createCountDown, startCountDown, scopeElement);
        return module;
    });
}

export { initialize }
