import dataAttributeAction from 'resources/js/data-attribute-action';

function getCallguideObject(callguideParam) {
    var errand;
    var entrance;

    callguideParam.split(',').forEach(function(item) {
        if (item.indexOf('errand') === 0) {
            errand = item.replace('errand=', '');
            // console.debug(errand = item.replace('errand=', ''));
        } else if (item.indexOf('entrance') === 0) {
            entrance = item.replace('entrance=', '');
            // console.debug(entrance = item.replace('entrance=', ''));
        }
    });

    return {
        'errand': errand,
        'entrance': entrance
    };

}

function initialize() {
    dataAttributeAction('data-callguide-chat', function(callguideParam) {
        if (window.startChatFlow) {
            var callguideObject = getCallguideObject(callguideParam);
            window.startChatFlow(callguideObject);
        }
    });
}

export { initialize }
