import * as domHandler from 'resources/js/utils/dom';

var bodyDom;
var currentTrigger;

function render(opt) {

    var modalElement = document.createElement('div');
    modalElement.style.display = 'none';
    modalElement.className = 'linkmodal';
    document.body.appendChild(modalElement);

    modalElement.innerHTML = '' +
        '<div class="linkmodal__row">' +
        '<div class="linkmodal__cell">' +
        '<div class="linkmodal__content ' + opt.modifier + '">' +
        '<svg class="modal-closebutton modal-closebutton_color-' + opt.closeButtonColor + '" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">' +
        '<path d="M138.024 96l44.814-44.814c11.712-11.712 11.712-30.696 0-42.408-11.706-11.706-30.696-11.706-42.402 0L95.622 53.592 50.814 8.778C39.102-2.928 20.112-2.928 8.4 8.778-3.306 20.49-3.306 39.474 8.4 51.186L53.214 96 8.4 140.814c-11.706 11.706-11.706 30.69 0 42.396 11.712 11.712 30.702 11.712 42.414 0l44.808-44.814 44.814 44.814c11.706 11.712 30.696 11.712 42.402 0 11.712-11.706 11.712-30.696 0-42.396L138.024 96z"/>' +
        '</svg>' +
        opt.content +
        '</div>' +
        '</div>' +
        '</div>';

    var closeButtonDom = modalElement.getElementsByClassName('modal-closebutton')[0];
    var contentDom = modalElement.getElementsByClassName('linkmodal__content')[0];

    contentDom.onclick = function(e) {
        e.stopPropagation();
    };

    return {
        modalElement: modalElement,
        closeElement: closeButtonDom
    };
}

function attachEventWithNoOpenTrigger(modal, opt) {
    var destroyCloseEvent = attachCloseEvent({
        closeTarget: modal.closeElement,
        closeFunction: close(modal.modalElement),
        runAfterClose: opt.runAfterClose,
        modalElement: modal.modalElement
    });

    return function() {
        destroyCloseEvent();
    };
}

function attachEvents(modal, opt) {

    var destroyOpenEvent = attachOpenEvent({
        openTrigger: opt.openTrigger,
        openFunction: open(modal.modalElement),
        runBeforeOpen: opt.runBeforeOpen,
        runAfterOpen: opt.runAfterOpen,
        modalElement: modal.modalElement,
        closeTarget: close(modal.modalElement)
    });

    var destroyCloseEvent = attachCloseEvent({
        closeTarget: modal.closeElement,
        closeFunction: close(modal.modalElement),
        runAfterClose: opt.runAfterClose,
        modalElement: modal.modalElement
    });

    return function() {
        destroyOpenEvent();
        destroyCloseEvent();
    };

}

function attachOpenEvent(open) {

    var openEventHandler = function(event) {
        event.preventDefault();
        event.stopPropagation();
        open.runBeforeOpen(this, open.modalElement);
        open.openFunction();
        currentTrigger = this;
        open.runAfterOpen(this, open.modalElement, open.closeTarget);
    };

    open.openTrigger.forEach(function(target) {
        target.addEventListener('click', openEventHandler);
    });

    var destroyOpenEvent = function() {
        open.openTrigger.forEach(function(target) {
            target.removeEventListener('click', openEventHandler);
        });
        if (open.modalElement && open.modalElement.parentNode) {
            open.modalElement.parentNode.removeChild(open.modalElement);
        }
    };

    return destroyOpenEvent;

}

function attachCloseEvent(close) {
    var closeEscEventHandler = function() {
        if (event.keyCode == 27) {
            close.closeFunction();
            close.runAfterClose(currentTrigger, close.modalElement);
        }
    };

    var closeEventHandler = function() {
        close.closeFunction();
        close.runAfterClose(currentTrigger, close.modalElement);
    };

    document.body.addEventListener('keyup', closeEscEventHandler, false);
    close.closeTarget.addEventListener('click', closeEventHandler);

    var destroyCloseEventHandlers = function() {
        close.closeTarget.removeEventListener('click', closeEventHandler);
        document.body.removeEventListener('keyup', closeEscEventHandler);
    };

    return destroyCloseEventHandlers;

}

function close(modal) {
    return function() {
        domHandler.removeClass(bodyDom, 'html-linkmodal');
        modal.style.display = 'none';
    };
}

function open(modal) {
    return function() {
        domHandler.addClass(bodyDom, 'html-linkmodal');
        modal.style.display = 'block';
    };
}

function triggerModal(modal, opt) {
    if (!opt.openTrigger.length) {
        open(modal.modalElement)();
        return;
    }

    if (typeof (opt.clickOnTargetAfterInit) === 'boolean' && opt.clickOnTargetAfterInit) {
        opt.openTrigger.forEach(function(currentTarget) {
            currentTarget.click();
        });
    } else if (opt.clickOnTargetAfterInit) {
        opt.clickOnTargetAfterInit.click();
    }
}

export default function(options) {
    bodyDom = document.body;

    var opt = {
        openTrigger: options.openTrigger,
        content: options.content || '',
        modifier: options.modifier || '',
        runAfterOpen: options.runAfterOpen ? options.runAfterOpen : function() {},
        runBeforeOpen: options.runBeforeOpen ? options.runBeforeOpen : function() {},
        runAfterClose: options.runAfterClose ? options.runAfterClose : function() {},
        clickOnTargetAfterInit: options.clickOnTargetAfterInit || false,
        closeButtonColor: options.closeButtonColor || 'black'
    };
    var modal = render(opt);

    modal.destroy = opt.openTrigger.length ? attachEvents(modal, opt) : attachEventWithNoOpenTrigger(modal, opt);
    triggerModal(modal, opt);

    return modal;
}
