import * as mediaQueries from 'resources/js/utils/media-queries';
import * as screenHandler from 'resources/js/utils/screen';



var timer;
var resizing = false;
var bodyElement = document.body;
var screenWidth = screenHandler.getScreenWidth();
var scopes = [];

function createScopeFromDom(scopeElement) {

    var targetElements = [].slice.call(scopeElement.querySelectorAll('[data-heightcorrection-target]'));
    var tmpTargets = {};

    targetElements.forEach(function(target) {
        var targetIndex = target.getAttribute('data-heightcorrection-target');
        if (!tmpTargets.hasOwnProperty(targetIndex)) {
            tmpTargets[targetIndex] = [];
        }
        tmpTargets[targetIndex].push(target);
    });

    return {
        min: mediaQueries.get(scopeElement.getAttribute('data-heightcorrection-min')),
        max: mediaQueries.get(scopeElement.getAttribute('data-heightcorrection-max')),
        targets: sortCalculationOrder(tmpTargets)
    };

}

function sortCalculationOrder(targets) {

    var sortedTargets = [];
    var i = 0;

    for (var targetIndex in targets) {
        sortedTargets[i] = targets[targetIndex];
        if (i > 0) {
            var current = i;
            for (var x = 0; x < i; x++) {
                var pre = i - (x + 1);
                if (sortedTargets[pre][0].contains(sortedTargets[current][0])) {
                    var tmp = sortedTargets[current];
                    sortedTargets[current] = sortedTargets[pre];
                    sortedTargets[pre] = tmp;
                    current = pre;
                }
            }
        }
        i++;
    }
    return sortedTargets;
}

function conformScope(scope) {
    var targets = scope.targets;
    if (isNaN(screenWidth) || ((scope.min === null || scope.min < screenWidth) && (scope.max === null || scope.max > screenWidth))) {
        targets.forEach(conformTarget);
    } else {
        // Disable height-correction in scope
        targets.forEach(function(target) {
            target.forEach(elementHeightReset);
        });
    }
}

function conformTarget(target) {

    var currentRowTopPosition = null;
    var rows = [];

    // Calculate tallest element in target
    target.forEach(function(element) {

        elementHeightReset(element);
        var elementOffsetTop = element.offsetTop;
        var elementHeight = element.offsetHeight; // .clientHeight

        // New row
        if (currentRowTopPosition === null || currentRowTopPosition !== elementOffsetTop) {
            currentRowTopPosition = elementOffsetTop;
            rows.push({
                tallestHeight: 0,
                domElements: []
            });
        }

        var currentRowIndex = rows.length - 1;
        rows[currentRowIndex].domElements.push(element);

        if (elementHeight > rows[currentRowIndex].tallestHeight) {
            rows[currentRowIndex].tallestHeight = elementHeight;
        }

    });

    // Set height
    rows.forEach(function(row) {
        var elements = row.domElements;
        elements.forEach(function(element) {
            element.style.height = row.tallestHeight + 'px';
        });
    });

}

function elementHeightReset(element) {
    element.style.height = 'auto';
}

function initialize() {
    scopes = [].slice.call(bodyElement.querySelectorAll('[data-heightcorrection-scope]')).map(function(scopeElement) {
        var scope = createScopeFromDom(scopeElement);
        conformScope(scope);
        return scope;
    });
    window.removeEventListener('resize', resize);
    window.addEventListener('resize', resize);

}

function resize() {
    if (!resizing) {

        resizing = true;
        var currentScreenWidth = screenHandler.getScreenWidth();

        if (currentScreenWidth === screenWidth) {
            resizing = false;
            return false;
        }

        clearTimeout(timer);
        timer = setTimeout(function() {
            screenWidth = screenHandler.getScreenWidth();
            scopes.forEach(conformScope);
            resizing = false;
        }, 300);
    }
}


export { initialize, resize }
